const api = {
    signUp: '/api/signup', // 注册
    login: '/api/login', // 登录
    logout: '/api/logout', // 退出登录
    signCode: '/api/code/$0/signup', // 注册时获取验证码
    loginCode: '/api/code/$0/signin', // 登录时获取验证码
    forGetCode: '/api/code/$0/retrieve/pwd/$1', // 忘记密码获取验证码
    retrievePas: '/api/retrieve/pwd', // 忘记密码
    downloadFile: '/api/file/download', // 下载图片

    // 操作当前用户所选企业 get：获取  put：修改
    userInCompany: '/api/user/in/company',

    // 我的应用
    supplyToken: '/api/supplier/short/token', // 获取应用跳转token

    usingList: '/api/supplier/mine/app/using', // 我的应用-使用中的列表
    expiredList: 'api/supplier/mine/app/expired', // 我的应用-停用的列表

    cityGroupDefault: '/api/city/group/default', // 获取城市组id
    getCitiesOfGroup: '/api/city/group/cities', // 获取城市组中的城市
    getCityGroups: '/api/city/groups', // 获取城市组
    cityGroup: '/api/city/group', // 添加城市组
    cityGroupDetail: '/api/city/group/$0', // 城市组详情
    addCompany: '/api/company', // 添加公司
    companyCategory: '/api/company/category', // 获取公司种类
    getEnterpriseBusinessInfo: '/api/enterprise/businessInfo', // 获取企业工商信息
    companyType: '/api/company/type', // 获取公司类型
    getCompanyInfo: '/api/company/$0/info', // 获取公司信息
    getCompanyDetail: '/api/company/$0/detail', // 获取公司信息(速度优化版)
    editCompanyInfo: '/api/company/$0/info', // 修改公司信息
    getDownCompanyInfo: '/api/company/down/$0/info', // 获取下游公司信息
    editDownCompanyInfo: '/api/company/down/$0/info', // 修改公司信息
    getCompanyList: '/api/more/company', // 经营企业列表
    getCompanyQuota: '/api/more/company/quota', // 获取可用次数
    changeCompanyStatus: '/api/more/company/$0/freeze/toggle', // 改变经营企业的状态：冻结、激活

    getCompanyProducts: '/api/products/$0', // 获取公司产品
    companyBusiness: '/api/all/business', // 获取公司业务范围
    getCompanySelf: '/api/companies', // 获取自己关联公司
    changeCurrCompany: '/api/company/change', // 切换公司
    channelCheckTime: '/api/channel/check/time/$0', // 渠道拜访时间
    deleteCompany: '/api/company/$0', // 删除企业
    criterionStatusTask: '/api/activity/criterion/$0/task/status', // 删除企业

    getCompanyProductsCount: '/api/productcount/$0/allcount', // 获取公司产品数量
    batchUpdateProductTemplate: '/api/productinfos/$0/batchupdate/template/$1', // 下载上游用户批量完善产品信息模版
    batchUploadProductInfos: '/api/productinfos/$0/batchupdate', // 上游用户上传的Excel下载链接批量完善产品信息
    batchUploadProdcutInfosProgress: '/api/productinfos/batchupdate/progress/$0', // 上游用户获取批量完善产品信息的进度状态

    searchUser: '/api/user/search', // 搜索成员
    addUser: '/api/user/$0/company/$1/role/$2', // 添加成员
    deleteUser: '/api/user/$0/company/$1', // 删除成员
    changeUser: '/api/user/$0/company/$1/role/$2', // 修改成员权限
    transferUser: '/api/user/$0/company/$1/transfer', // 转移权限
    exitUser: '/api/user/company/$0', // 退出企业

    userInfo: '/api/user/info', // 用户信息
    uploadImg: '/api/upload/img', // 上传头像
    UploadCompress: '/api/upload/compress', // 上传图片 压缩
    uploadCompressImg1M: '/api/upload/compress?compressSize=1024', // 上传图片并压缩至1M
    uploadCompressImg3M: '/api/upload/compress?compressSize=3072', // 上传图片并压缩至3M
    changePass: '/api/user/change/pwd', // 修改密码
    updataUserInfo: '/api/user/info', // 修改用户信息
    getUserDesignation: '/api/user/designation', // 读取用户备注名或姓名
    getAllPro: '/api/provinces/all', // 获取所有省份
    getAllcitys: '/api/$0/cities', // 获取所有城市
    UserList: '/api/users', // 查看用户列表
    getRole: '/api/user/company/$0/role', // 查看用户在该公司权限
    manageInfo: '/api/user/$0/company/$1', // 查看用户信息

    approveBtn: '/api/company/$0/apply/authentication', // 申请企业认证

    getProduct: '/api/productinfos', // 查看产品列表
    addProduct: '/api/products', // 添加产品列表
    addProduct1: '/api/products/$0', // 添加产品列表
    getCfda: ' /api/product/$0', // 查看cfda
    addAction: '/api/productinfo/$0/company/$1', // 添加产品
    searchProduct: '/api/productinfo/$0', // 查看产品信息
    getCategories: '/api/product/categories', // 获取产品类型
    editCostPrice: '/api/productinfo/$0/costPrice', // 修改成本价
    editproductinfos: '/api/productinfo/$0', // 获取产品信息列表
    deleteProduct: '/api/productinfo/$0', // 删除产品
    getDepartment: '/api/departments', // 获取科室
    getArea: '/api/area', // 获取城市区县接口
    getAreas: '/api/activity/registers/$0/area', // 获取地区
    addBase64: '/api/upload/img/base64', // 添加图片  Deprecated
    postBase64: '/api/upload/img/base64/v2', // 添加图片
    getDiseases: '/api/diseases', // 获取疾病

    getInvestments: '/api/investments', // 获取招商信息列表
    getProductInfos: '/api/productInfos/$0', // 获取产品信息列表
    getChannels: '/api/channels', // 获取招商渠道
    getContacts: '/api/contacts/$0', // 获取联系人
    getHospitalAreas: '/api/hospitalAreas', // 获取联系人
    publishMes: '/api/investments', // 发布招商信息
    getDetail: '/api/investments/$0/detail', // 招商信息详情
    deleteDetail: '/api/investments/$0', // 删除招商信息
    previewInfo: '/api/investments/$0/preview', // 预览招商信息
    previewInfo1: '/api/investments/$0/share', // 预览招商信息
    previewInfo2: '/api/investments/preview/$0', // 预览招商信息
    getStatData: '/api/investments/$0/stat', // 预览招商信息
    getStatDataByPhone: '/api/investments/$0/stat/phone', // 预览招商信息

    getBuyList: '/api/purchase/up/page', // 获取求购列表
    getBuyListInfo: '/api/purchase/$0/up/detail', // 获取求购详情
    deleteBuyListInfo: '/api/purchase/$0/uninterested', // 不感兴趣
    getInvestmentsList: '/api/investments/$0/selectList', // 获取招商信息
    pushBussiness: '/api/purchase/$0/push/$1', // 获取招商信息

    getMessageList: '/api/message/list', // 获取消息列表
    getMessageInfo: '/api/message/$0/detail', // 获取消息详情
    deleteMessageInfo: '/api/message/$0/delete', // 删除消息
    getMessageCount: '/api/message/count', // 删除消息

    getCountHome: '/api/home/$0/resource/count', // 首页获取数据
    getHome: '/api/investments/stat', // 首页招商统计
    readAll: '/api/message/allRead', // 全部已读

    getHospitals: '/api/hospitals', // 获取医院
    getCustomers: '/api/customers/titles', // 获取职称
    getPost: '/api/customers/posts', // 获取职位
    addCustomers: '/api/customers/type', // 添加客户类型
    getCustomersList: '/api/customers/types', // 获取客户类型
    addCustomer: '/api/customers/add', // 添加客户
    getCustomerInfoList: '/api/customers/list', // 获取客户列表
    getPromote: '/api/promote/products', // 查询推广产品列表
    searchProductAndroid: '/api/product/search', // 查询推广产品列表
    searchProductDetail: '/api/product/search/detail', // 查询推广产品详情
    searchFactory: '/api/product/factory', // 搜索厂商
    getPromotionDetail: '/api/promote/product/$0', // 获取推广产品详情
    SynchCustomer: '/api/visit/add', // 同步拜访计划
    recordsList: '/api/visit/records', // 拜访计划列表
    recordsDetail: '/api/visit/detail/$0/$1', // 拜访计划详情
    deleteRecords: '/api/visit/delete/$0', // 删除拜访计划
    syncsList: '/api/visit/syncs', // 同步列表
    visitDetail: '/api/visit/detail/$0', // 同步列表
    visitData: '/api/visit/companys/$0', // 拜访数据列表
    createList: '/api/visit/createUsers', // 创建人
    visitProduct: '/api/visit/companys/$0/products', // 产品列表
    reportFileList: '/api/report/files', // 我的报告列表
    uploadReport: '/api/report/upload', // 上传报告
    Upload: '/api/upload_file', // 上传文件
    deleteReport: '/api/report/file/$0', // 上传文件
    reportUploadList: '/api/report/file/submitter/$0', // 上傳人列表

    TemplatesList: '/api/activity/templates', // 模版列表
    CategoriesList: '/api/activity/categories', // 类别列表
    AddCategories: '/api/activity/category', // 新增活动类型
    CategoriesDetail: '/api/activity/category/$0', // 活动类型详情
    RegistersList: '/api/activity/registers', // 活动登记列表
    plansList: '/api/activity/plans', // 计划选择
    AddPlans: '/api/activity/plan', // 申请计划
    PlansDetail: '/api/activity/plan/$0', // 申请计划
    approvePlans: '/api/activity/plan/$0/approve', // 批准活动
    refusePlans: '/api/activity/plan/$0/refuse', // 拒绝活动
    approverList: '/api/activity/plan/approver', // 申请人列表
    registersDetail: '/api/activity/registers/$0', // 活动登记详情
    getPaperSignList: '/api/activity/registers/$0/paper/sign', // 获取纸质签到信息
    draftsList: '/api/activity/drafts', // 草稿箱列表
    changeApproval: '/api/activity/registers/$0/approval', // 活动登记修改状态
    withdraw: '/api/activity/registers/$0/withdraw', // 撤回活动登记
    getChooseDetail: '/api/activity/choose/plans/$0', // 原活动
    getPlanProduct: '/api/activity/products', // 新建计划的推广产品

    reportRroduct: '/api/report/file/product', // 报告选择cfda信息
    reportDetail: '/api/report/file/$0', // 报告详情
    reportDelete: '/api/report/file/$0/company/$1', // 删除报告
    reportUpload: '/api/report/upload/$0', // 报告详情
    ChangeNote: '/api/user/$0/company/$1', // 修改备注

    contanctData: '/api/contact/$0', // 签约
    idDelete: '/api/company/{id}/deleted', // 查询企业是否已删除
    companyTax: '/api/company/tax', // 获取开票信息
    editProtocol: '/api/contact/protocol/edit/$0', // 编辑协议
    repetitionProduct: 'api/productinfo/$0/repetition', // 查询是否有重复商品
    registerMenu: '/api/activity/register/$0/menu', // 活动登记tab
    searchCompany: '/api/company/authenticated', // 搜索公司
    partyaList: '/api/contact/partya/$0', // 上游查看签约列表1s
    partybs: '/api/contact/hasreport/partybs', // 上游查看有提交咨询报告的签约公司列表
    partybList: '/api/contact/partyb/$0', // 下游查看签约列表
    downloadPartyQualificationSummary: '/api/contact/partyb/qualifications/summary/v2', // 服务商资质汇总导出
    downloadPartyQualificationsZip: '/api/contact/partyb/qualifications/divide/v2', // 服务商资质集合下载压缩包
    partyaObj: '/api/contact/$0/partya', // 签约对象
    addPromote: '/api/promote/task/basic', // 创建推广任务
    getPromoteTask: '/api/promote/task/items', // 获取服务项目
    addPromoteTask: '/api/promote/task/$0/item', // 添加服务项目
    itemsPromoteTask: '/api/promote/task/$0/items', // 查询服务项目
    deletePromoteTask: '/api/task/$0/item/$1', // 删除服务项目
    getTaskBasic: '/api/promote/task/$0/basic', // 基础信息
    getMarketProduct: '/api/market/products/$0', // 获取产品列表
    getMarketUsers: '/api/task/$0/item/market/users', // 联系人列表
    addMarket: '/api/task/item/$0/market', // 添加市场列表
    getMarket: '/api/markets/$0', // 市场列表
    getMarketVisits: ' /api/task/item/market/$0/visits', // 市场列表
    visitsDetail: ' /api/task/item/market/$0/visits', // 市场列表
    exportMarketVisits: '/api/market/download/$0', // 数据导出
    promotetask: '/api/task/item/$0/activities', // 学术活动
    itempromotetask: '/api/task/$0/item/activity/items', // 选择学术活动
    addpromotetask: '/api/task/item/$0/activity', // 添加学术活动
    downloadPdf: '/api/promotetask/activity/download/$0/$1', // pdf下载
    deleteItempromotetask: '/api/promotetask/activity/$0/$1', // 删除
    getpromotereport: '/api/task/item/$0/report', // 报告
    similarReportFiles: '/api/report/similarinfo/files', // 包含相似性结果的咨询报告文件列表
    getSimilarityThreshold: '/api/report/similarity/threshold', // 读取公司的咨询报告相似度阈值
    querySimilarityFiles: '/api/reportfile/$0/comparedfiles', // 分页查询某个咨询报告的相似文档列表
    getAttachment: '/api/task/item/$0/attachments', // 附件分页
    addAttachment: '/api/task/item/$0/attachment', // 附件添加
    deleteREPORT: '/api/task/item/$0/report/$1', // 删除报告
    updateReport: '/api/task/item/$0/report/$1', // 更新报告关联关系吧
    putTask: '/api/promote/task/$0', // 提交推广
    marketPreview: '/api/task/item/$0/market/preview', // 预览
    upTasks: '/api/promote/receiver/tasks', // 上游推广任务 （废弃）
    upTasksV2: '/api/promote/receiver/tasks/v2', // 上游推广任务
    batchAudit: '/api/promote/task/batch/audit', // 上游批量审核任务
    batchAuditSignature: '/api/promote/task/collection/batch/signature/approve/batch', // 上游批量审核多页签章
    batchAuditCount: '/api/promote/task/batch/audit/count', // 查看上游批量审核任务数
    editSettlement: '/api/promote/task/$0/item/settlement', // 修改金额
    downloadmarket: '/api/task/download/visit/$0', // 明细下载
    downloadmarketpdf: '/api/task/download/$0/visit/summary', // 明细下载
    downloadLogin: '/api/task/download/$0/activity/summary', // 学术推广登记表下载
    participantsList: '/api/activity/$0/participants', // 参与人列表
    activityparticipants: '/api/activity/plan/$0/participants', // 参与人列表
    participantsSign: '/api/activity/participants/$0/$1/sign', // 签到
    registerparticipants: '/api/activity/register/$0/participants', // 参与人列表
    contactsList: '/api/activity/$0/$1/contacts', // 联系人列表
    participantList: '/api/activity/$0/$1/participants', // 联系人列表
    contactsactivity: '/api/activity/$0/contacts', // 联系人列表
    contactsPart: '/api/customers/createUsers/$0', // 创建人列表
    pDown: '/api/activity/$0/participants/download', // 参与人名单下载
    signFn: '/api/activity/participants/$0/sign', // 补签
    deleteparticipants: '/api/activity/participants/$0/$1', // 删除参与人
    signedOWN: '/api/activity/$0/$1/sign/download', // 签到表下载
    contactsListPart: '/api/customers/contacts/$0', // 联系人列表
    contactsDetail: '/api/customers/$1/contacts/detail/$0', // 联系人详情
    deleteBatch: '/api/customers/delete/batch', // 批量删除联系人
    activityPlanCheck: '/api/activity/plan/$0/check', // 校验联系人
    checkBudgetItem: '/api/contact/protocol/$0/budget/item/all', // 根据协议id查询所有协议子预算
    ruleStatusCompany: '/api/approval/rule/config/detail/$0/$1/status', // 查询上游规则开启

    addAgenda: '/api/activity/plan/$0/agenda', // 添加议程
    visitBatch: '/api/visit/$0/batch', // 批量删除
    addInvitation: '/api/activity/plan/invitation/$0', // 添加邀请函
    PlanCheck: '/api/activity/plan/check', // 添加活动计划校验
    getInvitation: '/api/activity/plan/$0/invitation', // 查询邀请函
    InvitationDown: '/api/activity/plan/invitation/download/$0', // 邀请函下载
    InvitationUpdate: '/api/activity/plan/invitation/update/$0', // 邀请函更新
    accessibleItems: '/api/task/$0/item/accessible', // 查询可以创建的服务项目
    // serviceTask: '/api/task/$0/item/accessible', //获取服务项目
    // accessibleItem: '/api/task/item/accessible', //获取服务项目
    agendaQueryList: ' /api/activity/plan/agendaQueryList/$0', // 查询议程
    updateagendaQueryList: '/api/activity/plan/agenda/update/$0', // 更新议程
    budgetget: '/api/promote/task/$0/budget', // 更新议程
    associateDe: '/api/customers/associate', // 批量删除
    activityChange: '/api/task/item/activity/$0', // 更新议程
    taskChange: '/api/promote/task/$0/$1', // 更新议程
    activityDown: '/api/task/download/$0/activity/$1', // 更新议程
    budgetDown: '/api/task/download/$0/budget', // 预算表下载
    promotionTaskSubdivisionSupport: '/api/promote/task/$taskId/budget/subdivision/support', // 预算表细分支持
    promotionTaskSubdivsionDetail: '/api/promote/task/$taskId/budget/group', // 细分详情
    promoteTaskAllBudget: '/api/promote/task/$0/budget/group', // 任务结算表
    settlementDown: '/api/task/download/$0/settlement', // 结算表下载
    settlementget: '/api/promote/task/$0/settlement', // 结算表
    getSettlements: '/api/promote/task/$0/settlement/group', // 结算表新接口
    getQR: '/api/wx/app/activity/plan/$0/url', // 获取小程序路径
    deleteContact: '/api/customers/contacts/delete/$0/$1', // 删除联系人
    uploadCustomers: '/api/customers/upload/$0/$1', // 批量导入客户
    customersZhi: '/api/customers/$0/users', // 客户指派列表
    associate: '/api/customers/associate/$0', // 批量指派
    contactsDetailList: '/api/customers/contacts/$0/user', // 批量指派
    deleteCustomers: '/api/customers/delete/$0', // 删除客户
    customersDown: '/api/customers/download', // 客户模版下载
    taskCustomers: '/api/customers/task/$0', // 查询进度
    deletePlan: '/api/activity/$0/plan/$1', // 删除计划
    deleteRegisters: '/api/activity/$0/registers/$1', // 删除计划登记
    downloadCso: '/api/promote/task/$0/cso/download', // 推广协议下载
    asyncCompressDown: '/api/task/download/$0/collection/async', // 异步下载任务合集
    summaryActiveDown: '/api/activity/$0/$1/summary/download', // 学术总结下载
    contactDetail: '/api/contact/details/$0/$1', // 详情
    contactDelete: '/api/contact/$0', // 取消签约
    promoteLog: '/api/promote/task/$0/log', // 审批日志
    changeStatus: '/api/promote/task/$0/status', // 修改状态
    facilitatorList: ' /api/promote/facilitator/set/$0', // 服务商列表
    contactCompany: '/api/contact/company/$0', // 服务商列表
    protocolsList: '/api/contact/$0/protocols', // 服务商协议列表
    signProtocol: '/api/contact/$0/protocol', // 签订协议
    contactCheck: '/api/contact/protocol/$0/check', // 校验协议与公司已有协议的重复性
    deleteCo: '/api/contact/$0', // 取消协议
    protocolBudget: '/api/contact/$0/protocol/$1/budget', // 更新预算
    protocolDetail: '/api/contact/$0/protocol/$1', // 协议详情
    protocolsLists: '/api/contact/partyb/$0/protocols', // 下游查看协议列表
    protocolDetails: '/api/contact/protocol/$0', // 下游查看协议列表
    protocolStatistical: '/api/contact/protocol/statistical', // 查询协议统计(上游)
    downloadProtocolStatistical: '/api/contact/protocol/$0/statistical/download', // 下载协议统计Excel(上游)
    partyaNameList: '/api/contact/partyb/$0/partyaName', // 下游查看签约公司名
    upNumber: '/api/contact/$0/$1/up/number', // 下游查看协议号
    isWithinDate: '/api/contact/protocol/$0/isWithinDate', // 判断是否超过周期
    statusTwo: '/api/promote/task/status/$0', // 审批状态
    marketProducts: '/api/market/products/task/$0', // 审批状态
    downloadProtocol: '/api/contact/$0/protocol/download', // 审批状态
    freeze: '/api/contact/$0/freeze', // 冻结服务商
    contactfreeze: ' /api/contact/freeze/$0', // 解冻服务商
    complianceList: '/api/compliance/$0', // 合规标准分页
    complianceItems: '/api/compliance/$0/items', // 查询条件项
    productinfosList: '/api/productinfos/statistics', // 预算统计分页
    statisticsList: '/api/promote/tasks/statistics', // 预算统计详情
    tasksSelectList: '/api/promote/tasks/$0', // 选择任务列表
    getitems: '/api/task/$0/items', // 查询服务项
    answerDetail: '/api/task/item/question/$0/answer', // 问卷详情
    marketsList: '/api/task/item/$0/markets', // 临床数据
    productsmarket: '/api/task/$0/item/market/products', // 临床数据推广产品
    marketUpdata: '/api/task/item/market/$0', // 更新结算金额
    deletePart: '/api/task/item/attachment/$0', // 更新结算金额
    subkeyList: '/api/activity/registers/subkey', // 更新结算金额
    qualificationList: '/api/$0/service/qualification', // 服务项目资质列表
    settlementsList: '/api/$0/standard/settlements', // 结算标准列表--线下签约
    settlementsList1: '/api/$0/standard/settlements/$1', // 结算标准列表2
    settlementEdit: '/api/$0/standard/settlement/$1', // 结算标准修改
    activitySettlementEdit: '/api/$0/standard/settlement/task', // 结算标准会议限制修改
    settlementStandard1: '/api/$0/standard/settlement/$1/$2', // 结算标准修改
    taskDownload: '/api/task/download/$0/list', // 附件列表
    wordList: '/api/$0/word', // 敏感词列表
    // validateItem: '/api/$0/task/item/$1/sensitive/task', //检索敏感词（废弃）
    validateTask: '/api/$0/task/$1/sensitive/task', // 检索敏感词
    // sensitiveReport: '/api/$0/task/item/$1/sensitive', //检索报告（废弃）
    sensitiveReportV2: '/api/$0/task/$1/sensitive', // 检索报告
    sensitiveDetail: '/api/task/item/sensitive/$0', // 检索报告详情
    deleteWord: '/api/word/$0', // 删除敏感词
    protocolLogs: '/api/contact/protocol/log', // 预算日志
    protocolLOg: '/api/contact/protocol/$0/budget', // 预算日志
    protocolEdit: '/api/contact/$0/protocol/$1/budget', // 预算修改
    statisticsPro: '/api/productinfos/statistics', // 产品统计
    productinfobudget: '/api/productinfo/$0/budget', // 设置产品预算额度
    // statisticalTask: '/api/$0/task/statistical',        //设置任务
    statisticalTask: '/api/settle/task/statistical', // 设置任务
    statisticalEdit: '/api/task/statistical/$0', // 设置任务统计
    statisticalLogs: '/api/$0/task/statistical/log', // 操作日志
    statisticalDownTask: '/api/task/download/statistical/$0/$1', // 统计下载
    statisticalType: '/api/task/statistical/type', // 获取类型
    visitKeyInfo: '/api/promote/task/visit/keyInfo', // 关键信息
    visitFeedBack: '/api/promote/task/visit/feedback', // 客户反馈
    visitKeyInfoDetail: '/api/task/$0/item/market/visits/keyInfo', // 关键信息详情
    maintenancegetTaskBasic: '/api/task/$0/terminal/maintenance/option/detail/page', // 基础信息
    visitFeedBackDetail: '/api/task/$0/item/market/visits/feedback', // 客户反馈详情
    editKeyInfo: '/api/visit/task/$0', // 批量修改关键信息
    editFeedBack: '/api/visit/task/$0/feedback', // 批量修改客户反馈
    creatorList: '/api/promote/task/creator', // 创建人列表
    visitCriterion: '/api/$0/visit/criterion/$1', // 检查条件
    visitLimitation: '/api/task/item/$0/visit/client/limitation/$1', // 查询每日拜访上限
    visitsCreators: '/api/task/item/$0/visits/creators/$1', // 拜访数据提交人
    deleteVisit: '/api/task/item/$0/visit', // 删除客户数据
    deleteOnlineVisit: '/api/task/item/$0/online/visits', // 删除远程拜访
    customerCodes: '/api/customer/code', // 查询客户码
    associatedList: '/api/customer/code/associated', // 查询客户码关联客户
    activationCode: '/api/$0/customer/code/activation', // 激活客户码
    compliancePage: '/api/compliance/protocol/$0', // 下游查看合规标准
    activitySearch: '/api/activity/criterion/$0', // 获取检索列表
    criterionStatus: '/api/activity/criterion/$0/task', // 获取检索状态
    criterionParticipants: '/api/activity/criterion/$0/participants', // 一致性列表
    criterionCost: '/api/activity/criterion/$0/cost', // 合理性列表
    criterionItem: '/api/activity/criterion/$0/item', // 吻合列表
    taskActivity: '/api/$0/task/$1/activity/check', // 任务学术检索结果
    taskActivityStatus: '/api/$0/task/$1/activity/check/status', // 任务学术检索状态
    activityCondition: '/api/$0/$1/activity/check/condition', // 设置学术检索条件
    activityHoldDetail: '/api/task/activity/check/hold/$0', // 频率详情
    activityparticipantsDetail: '/api/task/activity/$0/check/participants', // 一致性详情
    activityStatistics: '/api/$0/task/$1/activity/check/Statistics', // 合理性详情
    deleteIem: '/api/task/item/activity/$0', // 删除学术活动
    registersReview: '/api/activity/registers/$0/info/review', // 活动登记信息快审
    taskReview: '/api/$0/task/item/activity/$1/review', // 活动登记信息快审
    itemActiveName: '/api/$0/task/$1/item/activity/name', // 活动登记信息快审
    customersDepart: '/api/$0/customers/departments', // 客户科室
    customersCities: '/api/$0/customers/cities', // 客户城市
    customersHos: '/api/$0/customers/hospitals', // 客户医院
    customersHosV2: '/api/customers/hospitals', // 客户医院V2
    partyaInfo: '/api/contact/partya/$0/info', // 所有签约对象
    protocolProduct: '/api/contact/$0/protocol/product', // 所有签约对象产品
    TemplateIndex: '/api/task/download/index/template', // 模版首页
    TemplateMenu: '/api/task/download/$0/$1/collection/menu', // 合集下载菜单
    TemplatePreview: '/api/task/download/$0/index/template/preview/url', // 模版首页预览
    refusedList: '/api/refused/reason/$0', // 拒绝理由
    guideModal: '/api/$0/guide', // 新手指南
    collectType: '/api/info/collect/promote/type', // 推广项目类型
    getCommonField: '/api/info/collect/template/$0/common/field', // 获取常用模版字段
    addTemplate: '/api/info/collect/template', // 新增模版
    templateList: '/api/info/collect/$0/template', // 模版列表
    templateUserList: '/api/info/collect/$0/template/user', // 模版创建人列表
    getCollect: '/api/info/collect/template/$0', // 获取模版
    collectRegisterList: '/api/info/collect/$0/register', // 登记信息列表
    submitterData: '/api/info/collect/submitter/$0', // 提交人列表
    collectDraft: '/api/info/collect/$0/register/draft', // 登记信息草稿箱列表
    templateAll: '/api/info/collect/$0/template/$1', // 获取下拉模版信息
    AddRegister: '/api/info/collect/register', // 新建信息登记
    registerDetail: '/api/info/collect/register/$0', // 信息登记详情
    DownloadRes: '/api/info/collect/register/$0/download', // 下载信息登记详情
    taskItemInfo: '/api/task/item/$0/info', // 获取推广任务终端调研列表
    taskItemInfoList: '/api/task/$0/item/info', // 获取推广任务选择终端调研列表
    deleteInfoList: '/api/task/item/$0/info/$1', // 删除信息
    upDataInfoMoney: '/api/task/item/$0/info/$1', // 信息更新金额
    templateExist: '/api/info/collect/template/number/exist', // 检查编号是否存在
    templateCopy: '/api/info/collect/template/copy', // 复制代码创建模版
    terminalInfo: '/api/promotetask/$0/terminal/research/check', // 信息收集检索结果
    readTerminalCheckStatus: '/api/promotetask/$0/terminal/research/check/status', // 查询信息收集检索结果状态V2
    terminalName: '/api/terminal/research/check/$0/name', // 终端信息唯一性
    terminalNameDetail: '/api/terminal/research/check/$0/$1/name/info', // 终端信息唯一性详情
    terminalStatistics: '/api/terminal/research/check/$0/statistics', // 终端信息完整性
    terminalTemplate: '/api/terminal/research/check/$0/quote', // 终端模版引用正确性
    terminalCondition: '/api/terminal/research/check/$0/condition', // 添加条件字段
    terminalStatisticsItem: '/api/terminal/research/check/$0/item',
    participantsEX: '/api/activity/participants/$0', // 编码导入客户
    registersEx: '/api/activity/registers/code/exist/$0', // 编码状态判断
    taskCheck: '/api/promote/task/$0/check', // 发送推广任务的检查
    pharmacysList: '/api/$0/pharmacys', // 药店信息列表
    pharmacyUpload: '/api/pharmacy/upload/$0', // 药店导入
    pharmacyDown: '/api/pharmacy/download', // 药店模版下载
    pharmacyTask: '/api/pharmacy/task/$0', // 查询上传进度
    clerksList: '/api/pharmacy/$0/clerks', // 店员列表
    pharmacyDetail: '/api/pharmacy/$0', // 药店信息详情
    pharmacysAssign: '/api/$0/pharmacy/assign/members', // 药店指派列表
    pharmacysAssignList: '/api/$0/pharmacy/assign/pharmacys', // 药店药店指派列表
    pharmacyAss: '/api/pharmacy/assign', // 药店指派
    pharmacysOwn: '/api/pharmacy/assign/own/$0/pharmacys', // 已经指派药店列表
    pharmacysDelete: '/api/pharmacy/assign/$0/$1', // 取消指派药店
    assignList: '/api/pharmacy/$0/assign', // 客情信息列表
    pharmacyVisit: '/api/visit/pharmacy/$0', // 药店拜访列表
    pharmacyProduct: '/api/visit/pharmacy/$0/product', // 药店拜访列表推广产品列表
    pharmacySubmitter: '/api/visit/pharmacy/submitter/$0', // 药店拜访列表提交人列表
    pharmacyBatch: '/api/visit/pharmacy/$0/batch', // 药店拜访批量删除
    pharmacysOwnList: '/api/$0/own/pharmacys', // 药店我的客户列表
    pharmacysBase: '/api/pharmacys/base', // 药店我的客户列表
    pharmacyPosts: '/api/pharmacy/posts', // 职务列表
    pharmacyAdd: '/api/pharmacy', // 添加药店
    deletePharmacy: '/api/pharmacy/assign/$0', // 删除我的药店
    pharmacyItems: '/api/task/item/$0/pharmacy', // 药店拜访列表/
    pharmacyMoney: '/api/task/item/pharmacy/$0', // 药店拜访列表更新结算金额
    pharmacyProductList: '/api/task/$0/item/pharmacy/products', // 药店拜访推广产品
    pharmacyUser: '/api/task/$0/item/pharmacy/users', // 药店拜访提交人
    pharmacyDownPart: '/api/task/download/$0/pharmacy/summary', // 药店拜访清单下载
    taskPharmacy: '/api/visit/task/$0/pharmacy', // 药店拜访预览列表
    visitDaily: '/api/visit/pharmacy/$0/daily', // 药店每日拜访
    pharmacyproduct: '/api/visit/pharmacy/$0/product', // 药店推广产品
    pharmacyInfo: '/api/visit/pharmacy/product/info', // 药店推广产品
    assignsDelete: '/api/pharmacy/$0/assigns', // 批量移除指派
    pharmacyDownLoad: '/api/visit/pharmacy/download/$0/$1', // 批量移除指派
    pharmacysSingle: '/api/pharmacy/assign/$0/pharmacys', // 单个指派列表
    AddVisit: '/api/visit/pharmacy', // 新增药店拜访
    AddClerk: '/api/pharmacy/$0/clerk', // 新增店员
    editClerk: '/api/pharmacy/clerk/$0', // 編輯店员
    pharmacyItemDelete: '/api/task/item/pharmacy/$0', // 移除数据
    pharmacyVisitDetail: '/api/task/item/pharmacy/$0/visits', // 拜访药店详情列表
    pharmacyItemVisits: '/api/task/item/pharmacy/$0/visit', // 拜访药店详情批量移除
    productPromoteList: '/api/visit/pharmacy/$0/productPromote', // 药店拜访产品
    downloadpharmacy: '/api/task/download/pharmacy/visit/$0', // 药店拜访产品
    pharmacySalesman: '/api/pharmacy/check/salesman/$0', // 药店拜访每日拜访上限
    pharmacyLimitation: '/api/pharmacy/check/limitation/$0', // 药店拜访每月拜访上限
    pharmacyPosition: '/api/pharmacy/check/visit/location/$0', // 药店拜访定位异常详情
    pharmacyPositionCompare: '/api/pharmacy/check/visit/location/$0/compare', // 药店拜访定位有效性-对比定位
    pharmacyInterval: '/api/pharmacy/check/time/interval/$0', // 药店拜访每日拜访时间间隔
    pharmacyTime: '/api/pharmacy/check/time/$0', // 药店拜访每日拜访时间
    pharmacyCity: '/api/pharmacy/check/city/$0', // 药店拜访每日拜访城市
    pharmacyCheck: '/api/$0/$1/pharmacy/check', // 药店拜访检索结果查询
    pharmacyTaskList: '/api/$0/$1/pharmacy/check/task', // 药店拜访检索结果任务
    pharmacyAbnormal: '/api/pharmacy/check/abnormal', // 检索详情
    abnormalUser: '/api/pharmacy/check/abnormal/users', // 药店检索详情提交人列表
    pharmacyConditions: '/api/$0/$1/pharmacy/check/conditions', // 药店检索详情设置条件
    pharmacyDeVisit: '/api/pharmacy/check/$0/visit', // 药店检索详情删除
    pharmacyUserDelete: '/api/pharmacy/check/$0/abnormal/users', // 药店检索详情删除
    promoteLocal: '/api/promote/task/company/$0/local', // 本地管理库分页
    promoteLocalAdd: '/api/promote/task/company/local', // 本地管理库添加
    promoteLocalDel: '/api/promote/task/company/local/$0', // 本地管理库删除
    taskLocal: '/api/promote/task/$0/local', // 存档推广任务
    reportCheck: '/api/task/$0/item/report/check/$1', // 获取咨询报告
    limitationChannel: '/api/channel/check/limitation/$0', // 获取渠道检索每月拜访上限数据
    limitationChannel1: '/api/channel/check/limitation/month/$0', // 获取渠道检索每月拜访上限数据
    channelUserCheck: '/api/channel/check/$0/abnormal/users', // 渠道拜访详情提交人
    channelTaskCheck: '/api/$0/$1/channel/check/task', // 查询渠道拜访检索结果
    channelAbnormal: '/api/channel/check/abnormal', // 查询渠道拜访数据结果
    getChannelList: '/api/channel/name/check/abnormal', // 查询渠道拜访异常项-每个渠道每月拜访-的渠道列表
    checkHasRoleSeePromoteTask: '/api/promote/task/$0/check/user', // 校验当前用户是否可以查看此任务
    reportCriterion: '/api/$0/report/criterion/$1', // 获取咨询报告条件
    reportSimilarity: '/api/task/$0/item/report/check/$1/similarity', // 获取咨询报告相似性列表
    reportSimilarityDetail: '/api/task/$0/item/report/check/$1/similarity/$2', // 获取咨询报告相似性详情
    registerFlow: '/api/info/collect/register/flow', // 流向登记新建
    collectUpload: '/api/info/collect/upload/$0', // 流向登记excel上传
    collectDownload: '/api/info/collect/download/$0', // 流向登记excel模版下载
    editFlow: '/api/info/collect/register/flow/$0', // 编辑流向编辑
    questionItemList: '/api/task/item/$0/question', // 推广任务问卷列表
    questionPrice: '/api/task/item/question/$0', // 修改问卷单价，或其他查询单个问卷的操作
    questionSummaryEdit: '/api/task/item/question/$0/summary', // 修改问卷总结
    SheetPdf: '/api/questionnaire/$0/sheet/pdf', // 答卷详情下载
    sheetImgs: '/api/questionnaire/sheet/$0/imgs', // 生成答卷预览
    channelTask: '/api/task/item/$0/channel', // 渠道拜访列表-推广任务
    channelProducts: '/api/task/$0/item/channel/products', // 渠道产品列表-推广任务
    channelUsers: '/api/task/$0/item/channel/users', // 渠道提交人列表-推广任务
    channelVisitDetail: '/api/task/item/channel/$0/visits', // 渠道详情列表-推广任务
    channelSummary: '/api/task/download/$0/channel/summary', // 渠道汇总表下载-推广任务
    channelVisitTaskDown: '/api/task/download/channel/visit/$0', // 渠道清单下载-推广任务
    promoteTasks: '/api/statistics/promote/tasks', // 按项目统计列表
    promoteTaskTerminal: '/api/statistics/promote/tasks/terminal', // 按终端统计列表
    promoteTaskTerminalDetail: '/api/statistics/promote/tasks/terminal/detail', // 按终端统计列表详情
    promoteTaskProtocolType: '/api/statistics/promote/tasks/protocol/type/$0', // 查询是否按自定义协议号合并
    updatePromoteTaskProtocolType: '/api/statistics/promote/tasks/protocol/type', // 更新是否按自定义协议号合并
    promoteTaskProduct: '/api/statistics/promote/tasks/product', // 按协议查询统计页面
    promoteTaskProductItems: '/api/statistics/promote/tasks/product/item', // 按协议查询统计页面执行次数汇总明细
    promoteTaskProductType: '/api/statistics/$0/merge/product/name', // 查询是否按自定义协议号合并
    promoteTaskProtocol: '/api/statistics/promote/tasks/protocol', // 按终端统计列表
    promoteTaskProtocolItem: '/api/statistics/promote/tasks/protocol/item', // 按协议查询统计页面-项目列表
    promoteTaskProtocolStatDetail: '/api/statistics/promote/tasks/protocol/detail', // 按协议查询统计页面-项目明细
    promoteTaskProtocolDetail: '/api/statistics/promote/tasks/terminal/detail', // 按终端统计列表详情
    promoteTaskShowType: '/api/statistics/promote/tasks/showType/$0', // 完成数设置类型
    updatePromoteTaskShowType: '/api/statistics/promote/tasks/showType', // 设置完成数类型
    uploadPromoteTasks: '/api/$0/project/stat/excel', // 按项目统计excel下载
    asnycUploadPromoteTasks: '/api/$0/project/stat/excel/async', // 按项目统计excel下载
    companyNameCheck: '/api/company/name/check', // 检验公司名称
    companyCodeCheck: '/api/company/code/check', // 检验公司ID
    companyCheckSuper: '/api/company/check/super', // 检验公司ID是否匹配
    companyNetwork: '/api/contact/network', // 与公司直接联网
    batchCompanyNetwork: '/api/contact/$0/network/batch', // 批量联网
    checkIdentity: '/api/$0/check/user/identity', // 验证用户名手机号
    DownCompanyDetail: '/api/company/down/$0/info', // 药事详情
    personalBusinessDetail: '/api/contact/$0/personal/business', // 自然人业务详情
    protocolTemplates: '/api/protocolTemplates', // 协议模版列表
    // protocolTemplatesAdd: '/api/$0/protocolTemplate', // 添加协议模版
    protocolTemplateSet: '/api/protocolTemplate/$0/$1', // 设为默认模版
    protocolTemplateDetail: '/api/protocolTemplate/$0', // 协议模版详情
    ruleConfigStatus: '/api/approval/rule/config/detail/$0/$1/status', // 查看规则开启
    protocolTemplateRename: '/api/protocolTemplate/rename/$0', // 协议模版重命名
    protocolTemplateSettlements: '/api/$0/$1/standard/settlements', // 协议模版结算标准
    anomalousList: '/api/$0/anomalous', // 异常项设置列表
    anomalousCodeList: '/api/$0/anomalous/$1/items', // 异常项设置开关列表
    anomalousItem: '/api/$0/anomalous/item/$1', // 异常项设置开关
    checkVisitTask: '/api/task/item/$0/visit/check/$1/task', // 异常项设置开关
    questionnaireAnomalousCondition: '/api/questionnaire/check/$0/condition', // 问卷调研异常项
    questionnaireCheckResult: '/api/questionnaire/check/$0/$1', // 问卷调研异常项检索结果
    questionnaireCheckStatus: '/api/questionnaire/check/status/$0/$1', // 查询问卷调研检索状态
    questionnaireCheckQuote: '/api/questionnaire/check/$0/title/quote', // 问卷题目完整性分页
    questionnaireCheckSimilarity: '/api/questionnaire/check/$0/summary/duplicate', // 问卷总结相似性结果分页
    questionnaireCheckTemplate: '/api/questionnaire/check/$0/quote/template', // 问卷检索模版正确性
    questionnaireCheckTemplatePatient: '/api/questionnaire/patient/check/$0/quote', // 问卷（患者）检索模版正确性
    questionnaireCheckTitle: '/api/{taskId}/questionnaire/check/assign/title/research', // 问卷指定题目专项检索
    questionnaireCheckTitleDetail: '/api/questionnaire/check/$0/assign/title/research/info', // 问卷指定题目专项检索详情
    questionnaireCheckTitleDetailAnswerInfo: '/api/$0/assign/title/research/answerInfo', // 问卷指定题目专项检详情 答题详情
    questionnaireCheckTitleDetailResearchers: '/api/$0/assign/title/research/researchers', // 问卷指定题目专项检详情 调研人员
    questionnaireSummaryDuplicateInfo: '/api/questionnaire/check/$0/summary/duplicate/info', // 问卷总结相似性详情
    questionnaireSummaryInfo: '/api/questionnaire/check/$0/summary/info', // 问卷总结相似性详情对比结果
    questionnaireQuoteInfo: '/api/questionnaire/check/$0/$1/title/quote/info', // 问卷题目完整性题目引用详情分页
    userUpdated: '/api/user/viewed', // 新手指南已阅
    companyScore: '/api/company/$0/score', // 资质分数
    downReportPdf: '/api/company/$0/report/pdf', // 下载资质报告
    productinfoBatchADD: '/api/productinfo/company/$0/batchAdd', // 批量添加产品
    standardGet: '/api/compliance/$0/standard', // 获取推广行为准则
    standardReturn: '/api/compliance/$0/standard/return/list', // 上游获取回执信息
    editStandard: '/api/compliance/$0/standard', // 编辑行为准则
    settlementsActived: '/api/$0/standard/settlements/$1', // 开启关闭服务资质
    companyAudit: '/api/company/contact/$0/audit', // 查询公司审计模式
    companyContactUsers: '/api/company/contact/$0/users', // 查询服务商公司成员
    companyContactMembers: '/api/company/tender/$0/users', // 投标企业成员
    applyTotherServiceList: '/api/contact/partya/$0', // 应用到其他服务商--列表
    applyTotherService: 'api/$0/business/permissions/copy', // 应用到其他服务商
    businessRoleList: '/api/$0/business/permissions', // 商务权限设置
    addBusinessRole: '/api/$0/business/permission', // 商务权限设置添加人员
    deleteBusinessRole: '/api/business/permission/$0', // 商务设置删除人员
    contactAudit: '/api/company/contact/$0/$1', // 切换公司的审计模式
    contactAuditList: '/api/company/contact/$0/auditor', // 获取审计人员
    auditorDelete: '/api/company/contact/$0/auditor/$1', // 删除审计人员
    protocolsLocal: '/api/contact/protocols', // 获取线下协议
    // protocolTemplateAdd: '/api/$0/protocolTemplate', // 添加协议模版
    addProtocolTemplate: '/api/protocolTemplate', // 新增模版协议（新）
    protocolTemplateText: '/api/protocolTemplate/text/$0', // 获取协议模版
    promoteAuditors: '/api/promote/task/$0/auditors', // 推广任务查询审批者
    updatePlanPromoteStatus: '/api/promote/plan/$0/audit', // 修改计划中的推广任务的状态
    protocolDetailSystem: '/api/protocolTemplate/detail', // 获取系统模版详情
    promoteTaskAuditor: '/api/company/$0/promote/task/auditor', // 审批人列表
    supplierList: '/api/company/$0/contact/$1/supplier', // 应用服务商列表
    contactRepetition: '/api/company/contact/$0/repetition', // 批量更新审批模式
    protocolTemplateDelete: '/api/protocolTemplate/$0/$1', // 删除协议模版
    protocolTemplateEdit: '/api/$0/protocolTemplate', // 编辑协议模版
    protocolTemplatesSystem: '/api/protocolTemplates/system/$0', // 获取系统模版
    serviceItem: '/api/company/service/item', // 服务项目
    channelConditions: '/api/$0/$1/channel/check/conditions', // 渠道拜訪條件設置
    templatesPreview: '/api/protocolTemplates/preview/$0', // 协议预览
    productsSetting: 'api/$0/promote/products', // 获取设置推广产婆
    taskItemServer: '/api/company/$0/task/item', // 服務項目統計
    // visitDepartment: '/api/task/item/$0/visit/department/match/$1', // 客户科室匹配分页（废弃）
    statisticalMark: '/api/company/$0/statistical/read/mark', // 查询上游公司统计状态
    QuestionnaireList: '/api/$0/questionnaires', // 查询问卷列表
    addQuestionnaire: '/api/$0/questionnaire', // 添加问卷
    updateQuestionnaire: '/api/$0/questionnaire', // 编辑问卷
    QuestionnaireCreator: '/api/$0/questionnaire/creators', // 问卷列表-创建人
    QuestionnaireSurvey: '/api/questionnaire/$0', // 查看问卷模版详情
    deleteQuestionnaireTemplate: 'api/questionnaire/$0/template/$1', // 删除问卷模版
    updateDefaultQuestion: '/api/question/default/update/$0', // 更新常用题目
    questionDefault: '/api/questionnaire/question/default/$0', // 获取默认问题
    getQuestionnaireIdByNo: '/api/questionnaire/get/id/$0', // 通过编号获取问卷id
    validProtocols: '/api/protocolTemplate/$0/validProtocols', // 查看已签约列表
    withdrawalProtocol: '/api/contact/protocol/withdrawal/$0', // 撤回协议
    companyScope: '/api/$0/company/scope', // 查看公司业务范围
    scopeLogs: '/api/company/scope/$0/logs', // 查看业务范围操作日志
    scopeCheck: '/api/$0/company/scope/check', // 业务范围检验
    scopeCheckFake: '/api/$0/company/scope/fake', // 业务范围检验
    dateCheck: '/api/$0/period/check', // 业务范围与服务商成立时间比较

    toggleOfTaskCollection: '/api/task/download/$0/collection/items/$1', // 推广任务合集下载开关
    subcontractServers: '/api/promote/task/subcontract/$0/items/$1/detail', // 分包项目
    visitImgDupCheckDetail: '/api/task/$0/visit/checkresult/imgdup/total/$1', // 临床拜访图片相似性检索结果-详情
    compareResult: '/api/enterprise/businessInfo/compareResult/$0', // 工商信息校验信息获取-通过id
    compareResult1: '/api/enterprise/businessInfo/compareResult', // 工商信息校验信息获取-通过服务商名称和id(短的)

    feedbackStart: '/api/info/feedback/check/$0/$1', // 竞品信息开始检索
    feedbackCheckStatus: '/api/promotetask/$0/info/feedback/check/status', // 竞品信息检索状态
    feedbackCheck: '/api/info/feedback/check/$0/$1', // 竞品信息检索结果
    feedbackCheckList: '/api/info/feedback/check/$0/name', // 竞品信息结果分页
    feedbackCheckDetailList: '/api/info/feedback/check/$0/name/info', // 竞品信息详情结果分页
    allUnNoticed: '/api/message/allUnNoticed/list', // 内容完整性－活动子项 单个活动检索
    operationMessage: '/api/message/operation/$0/status/$1', // 消息已读
    allListMessage: '/api/message/allList/$0', // 第一次进入邀请消息
    editDepart: '/api/user/company/$0/department', // 修改部门信息
    editPersonalSignFill: '/api/protocol/$0/pdf/fill', // 编辑签约获取收款信息
    personalScope: '/api/$0/personal/scope', // 查看公司业务范围
    // 工作台
    busData: '/api/$0/workbench/business', // 业务数据
    initData: '/api/$0/workbench/init/state', // 获取企业初始化状态
    downloadReport: `/node/workbench/$0/$1`, // 工作台报告下载
    projectMade: '/api/$0/workbench/statistics/tasks', // 推广项目构成
    wprkDataPDF: '/api/$0/workbench/pdf/data',
    // 业务线
    getCompanyProductLineRules: '/api/$0/productionLines/rules', // 获取产品线删除前判断
    getCompanyProductLines: '/api/company/$0/productionLines', // 任务审批权限的审批树接口
    downloadBusinessLine: `/node/pipeline/$0`, // 业务线报告下载
    deleteProductionLine: '/api/productionLines/$0', // 删除产品线
    deleteProductInProductionLine: '/api/productionLines/$0/products/$1', // 删除产品线中的产品
    productionLineRegion: '/api/productionLines/$0/regions', // 获取区域
    productionLineTree: '/api/productionLines/$0/regions/tree', // 获取区域(包含人员架构)
    addProductionLineTreeStaff: '/api/productionLineRegions/$0/staffs', // 添加区域人员
    replaceProductLineTreeStaff: '/api/productionLineRegions/staffs/$0', // 修改区域人员
    deleteProductLineTreeStaff: '/api/productionLineRegions/staffs/$0/delete', // 删除区域人员
    productionLines: '/api/$0/productionLines', // 产品线列表
    productionLinesStaffs: '/api/$0/productionLines/staffs', // 成员选择列表
    getProductionLinesProducts: '/api/$0/productionLines/products', // 获取公司产品列表
    addProductToProductionLine: '/api/productionLines/$0/products', // 添加产品到产品线
    getProductsByProductionLine: '/api/$0/productionLines/$1/products', // 获取产品线产品列表

    getRuleConfig: '/api/approval/rule/config/list/$0', // 查看规则
    applyRuleScope: '/api/approval/rule/config/apply/scope/$0', // 适用范围
    configDetailRule: '/api/approval/rule/config/select/content/$0', // 根据适用范围类型获取其他数据
    getRuleSelect: '/api/approval/rule/config/select/content', // 获取选择内容
    addRuleConfig: '/api/approval/rule/config', // 添加规则
    ruleConfigDetail: '/api/approval/rule/config/detail/$0', // 规则详情
    editRuleConfig: '/api/approval/rule/config/$0', // 修改规则
    taskRuleUpdate: '/api/$0/approval/rule/task/audit/automation', // 推广任务规则更新
    getSiginViewList: '/api/contact/company/$0/protocols', // 获取协议视图列表
    ruleDetail: '/api/approval/rule/config/detail/$0/$1', // 查看规则
    auditorPeople: '/api/company/$0/auditor/protocol', // 审批人列表
    auditorsProtocol: '/api/protocol/$0/auditors/protocol', // 审批人列表
    protocolLogStatus: '/api/contact/protocol/$0/log', // 审批日志
    scopeCorrect: '/api/company/$0/scope/correct', // 业务范围纠错  deprecated
    postScopeCorrect: '/api/scope/correct', // 业务范围纠错
    getScopeCorrect: '/api/scope/correct',
    checkSign: '/api/contact/$0/check/sign', // 检验经理权限
    contactButton: '/api/contact/protocol/$0/$1/button/view', // 协议详情按钮显示判断
    memberPermissionsStats: '/api/$0/member/$1/permissions/stats', // 公司成员的审批权限统计
    serviceAuditsStats: '/api/$0/service/audit/stats', // 服务商审批方式统计
    downloadServiceAuditsStats: '/api/download/$0/service/audit/stats', // 服务商审批方式统计下载
    planDeleteUser: '/api/marketing/plan/authorized/$0/deleted/user', // 服务商审批方式统计
    pharmacyOrien: '/api/pharmacy/check/orientation/$0', // 药店定位详情

    // 推广项目统计
    getStatisticByReceiver: '/api/company/$0/$1/statistic', // 根据服务商进行统计
    getStatisticByProtocol: '/api/company/protocol/$0/statistic', // 根据协议进行统计
    downloadProtocolBudgetTemplate: '/api/contact/$0/protocol/budget/$1/batchupdate/template', // 下载上游用户批量完善协议预算清单模版
    uploadProtocolBudgetExcel: '/api/contact/$0/protocol/budget/$1/batchupdate', // 上游用户上传的协议预算清单的Excel
    uploadProtocolBudgetProgress: '/api/contact/protocol/budget/batchupdate/progress/$0', // 上游用户获取批量完善协议预算清单的进度状态

    checkline: '/api/approval/rule/config/check/production/line/$0', // 检验审批流是否使用
    ruleRegulation: '/api/approval/rule/config/regulation', // 规则类型选择
    ruleConfigApply: '/api/approval/rule/config/apply/scope/$0/$1', // 规则类型选择
    // 按服务商统计
    partyBStatistics: '/api/company/$0/statistics/partyB', // 按服务商统计列表
    partyBStatisticsSummary: '/api/company/$0/$1/statistics/partyB/summary', // 获取汇总数据
    partyBStatisticsServiceItems: '/api/statistics/partyB/$0/service/items', // 推广项目
    partyBStatisticsExcel: '/api/company/$0/$1/statistics/partyB/excel', // excel下载
    downloadPartyBStatisticsPDF: '/node/statistic/company/$0/user/$1', // PDF下载
    // 投标
    getBiddingAuditors: '/api/tenders/$0/auditors', // 查看当前审批进程
    biddingList: '/api/$0/1/tenders', // 投标列表
    tenderDetail: '/api/tender/$0', // 投标详情
    getAptCon: '/api/qualification/standard/$0', // 获取企业资质标准设置内容,get获取，put 修改
    bidChangeStatus: '/api/tenders/$0/rejection', // 拒绝
    bidChangePass: '/api/tenders/$0/agreement', // 通过
    bidChangeAdd: '/api/tenders/$0/sign', // 添加至合作企业
    getNewBid: '/api/$0/tenders/new', // 获取是否收到新投标
    getApprover: '/api/$0/tenders/auditors', // 获取待审批人
    definedstandard: '/api/qualification/standard/$0/show', // 修改企业资质标准设置内容是否显示
    excelImport: '/api/excel/$0/legal/representative', // EXCEL导入法人代表名单

    // 任务统计
    paymentInfo: '/api/task/payment/info/$0', // 获取付款详情
    statisticalList: '/api/task/statistical/$0', // 获取付款列表
    addPayment: '/api/task/payment/info/statistical/$0', // 新增付款信息

    // 营销计划
    getMarketPlanList: '/api/$0/marketing/plans', // 获取计划列表
    getDefaultPlan: '/api/$0/marketing/current/plan', // 获取默认周期内的计划信息
    getNewMarketingPlan: '/api/$0/marketing/prepare/plan', // 获取新的营销计划
    getPlanProductLines: '/api/marketing/plan/$0/lines', // 计划的产品线列表
    addProductLineToPlan: '/api/marketing/plan/$0/production/line', // 添加计划的产品线
    unAddPlanProductLine: '/api/marketing/plan/$0/production/lines', // 计划产品线选择列表
    deletePlanProductLine: '/api/marketing/plan/production/line/$0', // 删除计划产品线
    syncPlanProductLine: '/api/marketing/plan/$0/lobs/sync', // 同步业务线
    planCustomProducts: '/api/marketing/plan/$0/custom/products', // 计划的自定义产品列表
    unAddPlanProducts: '/api/marketing/plan/$0/products', // 产品选择列表
    getMarketingPlanById: '/api/marketing/plan/$0', // 获取已创建的营销计划
    getMarketingPlanById1: '/api/$0/marketing/plan/$1', // 获取已创建的营销计划
    getBasicMarketingPlan: '/api/marketing/plan/$0/stats', // 获取已创建的营销计划
    getPlanCustomRegions: '/api/marketing/plan/$0/custom/regions', // 计划的区域
    deleteMarkrtingPlan: '/api/$0/marketing/plan/$1', // 删除营销计划
    updateMarketingPlan: '/api/marketing/plan/$0', // 编辑营销计划
    getMarkingPlanLineById: '/api/marketing/plan/line/$0', // 获取方案信息
    savePlanAndTarget: '/api/marketing/plan/line/$0/target', // 保存目标与预算
    getDefaultStrategy: '/api/marketing/plan/default/strategies', // 默认策略列表
    addStrategy: '/api/marketing/plan/line/$0/strategy', // 新增策略
    getEmptyProject: '/api/marketing/plan/self/strategy', // 获取空的推广项目列表
    deleteAllStrategies: '/api/marketing/plan/line/$0/strategies', // 删除方案中的所有策略
    deleteOneStrategy: '/api/marketing/plan/line/strategy/$0', // 删除策略
    editStrategy: '/api/marketing/plan/line/strategy/$0', // 编辑策略
    saveMakeMarketPlan: '/api/marketing/plan/line/$0/strategies', // 保存营销策略
    createMarketPlan: '/api/marketing/plan/$0/complete', // 生成营销计划
    exportMarketPlan: `/node/company/$0/plan/$1`, // 导出营销计划为PDF
    switchDefaultPlan: '/api/marketing/plans/$0/switch', // 切换计划
    marketPlanAuthorized: '/api/$0/marketing/plan/authorized', // 授权人列表
    addMarketPlanAuthorizedUser: '/api/$0/marketing/plan/authorized', // 添加授权人
    unAddAuthorizedUser: '/api/$0/marketing/plan/authorized/unAdd', // 添加授权人列表
    judgeUserIsAuthorized: '/api/$0/marketing/plan/isAuthorizer', // 是否是授权人
    deleteMarketingPlanAuthorizedUser: '/api/marketing/plan/authorized/$0', // 删除授权人
    updateMarketingPlanEditor: '/api/marketing/plan/$0/editor/$1', // 修改计划授权编辑人
    marketingPlanProductLineProdcuts: '/api/$0/marketing/plan/lines/$1/products', // 营销计划业务线产品
    copyAndCreateMarketingPlan: '/api/marketing/plan/$0/copy', // 复制新建计划

    // 模型配置
    conversionModel: '/api/company/$0/model', // 模型分页列表
    getCompanyConversionTables: '/api/company/$0/tables', // 获取公司换算表列表
    getConversionTableById: '/api/table/$0', // 获取换算表详情
    getConversionModelDetail: '/api/company/model/$0', // 模型详情
    initConversionTable: '/api/init/model', // 初始化换算表
    downloadDefaultTables: '/api/company/default/tables/download', // 下载系统预设换算表

    settlementInfoItems: '/api/standard/settlement/info/items', // 终端调研分级可选项目
    protocolSettlement: '/api/protocol/standard/settlement/$0', // 结算标准协议

    ruleStatus: '/api/approval/rule/config/$0/supplement/$1/status ', // 查询上游规则开启
    effectTask: '/api/promote/task/$0/$1/effect/summary', // 修改开展情况和总结
    effectTask2: 'api/promote/task/item/$0/effect/summary', // 修改开展情况和总结
    effectSummary: '/api/promote/task/$0/effect/summary/list', // 获取所有开展列表
    effectExpect: '/api/promote/task/$0/effect/expect/list', // 获取所有开展列表

    settlementRule: '/api/task/$0/settlement/rule', // 检查上游开启规则
    settlementRatio: '/api/task/$0/settlement/proportion/rule', // 检查上游是否开启结算项目费用占比(按类型统计)规则
    budgetRatio: '/api/task/$0/budget/proportion/rule', // 检查上游是否开启预算项目费用占比(按类型统计)规则

    // 战术分解
    planLineTactical: '/api/marketing/plan/line/$0/tactical', // 战术分解列表
    chooseModal: '/api/marketing/plan/$0/tactical/model', // 战术分解选择模型
    modalAppay: '/api/marketing/plan/$0/tactical/model/apply', // 战术分解模型应用全部产品
    saveTactical: '/api/marketing/plan/$0/tactical/model', // 战术分解模型应用全部产品
    prewviewModel: '/api/marketing/plan/$0/tactical/model/$1', // 战术分解模型预览
    effectDown: '/api/task/download/$0/effect/expect', // 申请表下载
    summaryDown: '/api/task/download/$0/effect/summary', // 总结表下载
    ruleConfig: '/api/approval/rule/config/detail/$0/3', // 检验上游是否开启计划审批

    protocolTemplateSwitch: '/api/protocolTemplate/$0/$1/switch', // 模版禁用/启用

    pharmacyVisitTime: '/api/pharmacy/check/one/time/$0', // 药店单次拜访时长
    pharmacyVisitIntegrality: 'api/pharmacy/check/visit/location/{checkId}/criterion', // 药店-终端位置信息完整性
    channelVisitTime: '/api/channel/check/one/time/$0', // 渠道单次拜访时长
    visitNotes: '/api/channel/check/include/visit/summary/$0', // 内容完整性-拜访小记
    channelVisitIntegrality: '/api/channel/check/location/criterion/$0', // 渠道-终端位置信息完整性

    visitsOptions: '/api/task/item/$0/visits/option ', // 拜访数据筛选项
    onlineVisitOptions: '/api/task/item/$0/online/visits/option', // 远程拜访数据筛选项
    repetitiveActive: '/api/activity/criterion/$0/repetitive/participant', // 活动同名参与人
    repetitiveActivity: '/api/activity/criterion/$0/repetitive', // 活动同名异常项

    auditorDistinct: '/api/company/contact/$0/auditor/distinct', // 任务审批成员列表
    protocolDistinct: '/api/company/contact/$0/auditor/protocol/distinct', // 协议审批成员列表
    plansDistinct: '/api/company/plans/$0/auditors/distinct', // 计划审批成员列表
    operatorsLog: '/api/$0/task/log/operators', // 审批成员列表
    repetitiveActivePlan: '/api/$0/task/$1/activity/$2/check/repetitive/participant', // 活动同名参与人推广任务
    activityFrequency: '/api/activity/criterion/$0/attend/frequency', // 学术活动参会频率合理性
    activityParticipant: '/api/activity/criterion/$0/attend/frequency/participant', // 学术活动参会频率合理性参会人信息

    activityFrequencyPlan: '/api/$0/task/$1/activity/$2/check/attend/frequency/participant', // 学术活动参会频率合理性参会人信息推广任务

    /** 异常项目检索结果 */
    reviewSwitch: '/api/criterion/review/$0/switch', // 修改通知下游审核结果开关
    reviewVisitChannel: '/api/criterion/review/$0/channel/visit', // 渠道拜访
    reviewVisitHospital: '/api/criterion/review/$0/hospital/visit', // 临床拜访
    reviewVisitPharmacy: '/api/criterion/review/$0/pharmacy/visit', // 药店拜访
    reviewVisitOnlineVisit: '/api/criterion/review/$0/online/visit', // 远程拜访
    reviewVisitFeedback: '/api/criterion/review/$0/feedback', // 竞品信息反馈
    reviewVisitReport: '/api/criterion/review/$0/report', // 咨询报告
    reviewVisitTerminal: '/api/criterion/review/$0/terminal', // 终端调研
    reviewVisitQuestionnaire: '/api/criterion/review/$0/questionnaire/$1', // 问卷调研
    reviewVisitActivity: '/api/criterion/review/$0/activity', // 学术活动
    reviewVisitInternal: '/api/criterion/review/$0/internal', // 内部培训
    reviewVisitActivityChild: '/api/criterion/review/$0/activity/$1/items', // 学术活动子项
    reviewVisitInternalChild: '/api/criterion/review/$0/internal/$1/items', // 内部培训子项
    reviewTerminalMaintenance: '/api/criterion/review/$0/terminal/maintenance', // 终端维护
    reviewCommercialFlow: '/api/criterion/review/$0/collect/commercial/flow', // 收集商业流向数据
    reviewTerminalMedicine: '/api/criterion/review/$0/collect/terminal/medicine', // 收集终端用药信息
    reviewCommercialDelivery: '/api/criterion/review/$0/commercial/delivery/agency/research', // 商业配送机构调研
    reviewHospitalAccess: '/api/criterion/review/$0/hospital/admittance', // 医院准入
    reviewPharmacyAccess: '/api/criterion/review/$0/pharmacy/admittance', // 药店准入
    reviewVisitQuestionnairePatient: '/api/criterion/review/$0/questionnaire/patient/$1', // 问卷调研（患者）
    reviewTypes: '/api/criterion/review/$0/types', // 数据汇总

    // 签章相关接口
    signatureSure: '/api/fadada/company/$0/info/checkIsPass', // 判断是否认证
    getUrlFromFaDaDa: '/api/fadada/company/$0/verify/url', // 获取企业实名地址
    signatureInfo: '/api/fadada/company/$0/info', // 获取企业认证信息
    signatureList: '/api/fadada/company/$0/signature/list', // 获取企业签章列表
    signatureUserList: '/api/fadada/sign/users', // 签章授权列表
    addSignature: '/api/fadada/person/signature', // 添加个人签章
    addCompanySignature: '/api/fadada/company/$0/signature', // 添加公司签章
    delCompanySignature: '/api/fadada/company/$0/signature/$1', // 删除公司签章
    defaultSignature: '/api/fadada/company/$0/default/signature', // 设置默认签章
    fadadaWx: '/api/fadada/company/$0/signature/order/wx', // 微信购买
    fadadaWxStatus: '/api/fadada/signature/order/$0/status', // 微信购买状态
    fadadaPayList: '/api/fadada/company/$0/signature/order/page', // 支付明细列表
    fadadaPayAuth: '/api/fadada/company/$0/auth/$1', // 企业授权给个人
    fadadaCheck: '/api/fadada/check/infoAndAuth', // 签约检查
    fadadaSign: '/api/contact/company/$0/protocol/$1/fadada/sign', // 电子签章
    fadadaPage: '/api/fadada/company/$0/useInfo/page', // 签章使用记录
    fadadaVerifyAuth: '/api/fadada/company/$0/verify/auth/$1', // 公司授权个人实名
    fadadaisAbleESign: '/api/fadada/check/isAbleESign/$0', // 公司授权个人实名
    approveBatchSign: '/api/promote/task/$0/collection/batch/signature/approve', // 审批多页签章
    downloadBatchSignPdf: '/api/promote/task/$0/collection/batch/signature/generate/pdf/download', // 下载多页签章文件
    downloadBatchSignZip: '/api/promote/task/$0/collection/batch/signature/generate/pdf/zip/download', // 下载多页签章文件
    getBatchSignLog: '/api/promote/task/$0/collection/batch/signature/log', // 分页查询多页签章审批日志

    // 个人签章
    personSignInfo: '/api/fadada/person/info', // 个人签章信息
    personSignInfoList: '/api/fadada/person/signature/list', // 个人签章列表

    personGetCode: '/api/fadada/person/verify/code', // 获取验证码
    fadadaCheckAuth: '/api/fadada/company/$0/verify/auth/$1/check', // 查询是否在别的公司被授权
    setSelfSignDefault: '/api/fadada/person/default/signature', // 设置个人默认签章
    deleteSelfSign: '/api/fadada/person/signature/$0', // 删除个人签章

    // 微信绑定
    getOpenId: '/api/wxggh/get/openid', // getOpid
    queryBind: '/api/wxggh/query/principal', // 查询是否绑定
    sendBind: '/api/wxggh/wx/login', // 微信公众号账号绑定
    clearBind: '/api/wxggh/wx/login/out', // 解除微信公众号账号绑定
    getCodeWx: '/api/wxggh/get/dimensional/code/$0/1', // 获取二维码
    lisenBindResultWX: '/api/wxggh/get/scan/binding/error/$0:1', // 获取扫码绑定的错误信息

    // 自营模式
    selfLogs: '/api/$0/self/support/logs', // 日志列表
    lisenBindResult: '/api/self/support/bind', // 绑定
    selfSupportBind: '/api/self/support/bind', // 自营绑定post接口
    unBind: '/api/self/support/$0/untying', // 解绑
    selfList: '/api/$0/self/support/list', // 检索选项
    selfScenesList: '/api/self/support/log/scenes', // 应用场景列表
    directlyBulletin: '/api/$0/directly/bulletin', // 业务简报
    autoTeamNumber: '/api/self/support/$0/bind/down/user', // 药事成员列表
    deleteAutoTeamNumber: '/api/self/support/bind/user/$0', // 解绑
    addAutoTeamNumber: '/api/self/support/bind/user', // 绑定
    autoTeamUser: '/api/self/support/$0/bind/up/user', // 管家成员列表

    // 问卷
    questionnaireAssign: '/api/$0/questionnaire/$1/assignable', // 查询可以指派的客户
    questionnaireHasAssign: '/api/$0/questionnaire/$1/assignment', // 查询指派的客户
    deleteQusetAssign: '/api/questionnaire/assignment/$0', // 删除指派
    questionnaireSurveyList: '/api/$0/questionnaire/survey', // 查询调研列表
    publicationUser: '/api/questionnaire/$0/publication/user', // 用户开始调研
    publicationCheck: '/api/questionnaire/$0/publication/check', // 结束调研的时候检查
    questionnairePlanUser: '/api/questionnaire/$0/plan/user', // 用户历程
    signatureIden: '/api/questionnaire/signature/identifier', // 获取签名token
    signatureImage: '/api/questionnaire/signature/image', // 签名
    planSign: '/api/questionnaire/$0/plan/sign', // 批量签到
    analyseList: '/api/questionnaire/$0/analyse', // 答卷分析列表
    analysable: '/api/questionnaire/$0/analysable', // 答卷分析历程
    analysePlanList: '/api/questionnaire/$0/analyse/$1/plan', // 关联的历程
    analysePlanListDel: '/api/questionnaire/$0/analyse/$1/$2', // 删除关联的历程
    analyseDetail: '/api/questionnaire/$0/analyse/$1', // 问卷分析
    analyseConclusion: '/api/questionnaire/analyse/$0/conclusion', // 问卷总结
    chooseAnalyse: 'api/questionnaire/$0/analyse/$1', // 选择历程
    AnswerSheetPdf: '/api/questionnaire/sheet/$0/pdf', // 答卷pdf
    AnswerSheetAnalysis: '/api/questionnaire/$0/analysis', // 答卷分析
    CheckAnswerSheet: '/api/questionnaire/$0/question/$1', // 答卷分析查看问题关联答卷
    AnswerSheetList: '/api/questionnaire/$0/sheet', // 获取答卷列表
    checkSheetQuestionnaire: '/api/questionnaire/sheet/$0/check', // 检查问题是否被访问过
    questionnaireProducts: '/api/$0/questionnaire/product', // 问卷列表-产品
    activatedQuestionnaire: '/api/$0/questionnaire/activated', // 问卷启用/禁用
    batchActivatedQuestionnaire: '/api/questionnaire/activated/batch/$0', // 批量启用/禁用问卷
    previewQuesTemplate: '/api/questionnaire/$0/imgs', // 问卷预览
    previewQuesTemplate1: '/api/questionnaire/imgs', // 问卷预览

    // 资讯
    addInformation: '/api/$0/information', // 添加咨询
    informationList: '/api/$0/informations', // 资讯库列表
    informationDetail: '/api/information/$0', // 咨询详情
    informationPreview: '/api/information/preview', // 咨询预览
    previewInformationDetail: '/api/information/preview/$0', // 预览详情
    informationReleaseList: '/api/$0/information/release', // 发布资讯列表
    releaseInformation: '/api/information/$0/release', // 发布资讯
    updateReleaseInformation: '/api/information/release/$0', // 编辑发布的资讯
    informationGroup: '/api/$0/information/group/fold', // 服务商分组
    informationCompanies: '/api/$0/information/release/contact', // 服务商选择列表
    informationReleaseTargetType: '/api/information/release/$0/target-type', // 改变资讯限制客户类型
    releaseInformationDetail: '/api/information/release/$0', // 发布资讯详情
    informationBrowseStat: '/api/information/release/$0/browse/stat', // 浏览情况
    informationReleaseContribute: '/api/information/release/$0/contribute', // 服务商贡献情况
    informationReleaseTrend: '/api/information/release/$0/trend', // 总趋势
    longRangeDetailList: '/api/task/item/market/$0/online/visits', // 远程拜访详情列表
    downloadCustomerReadStatistics: '/api/information/download/$0/customer/read', // 临床客户浏览数据下载
    downloadProviderContribute: '/api/information/download/$0/contribute', // 服务商贡献清单下载
    // 准入接口
    admittanceCreate: '/api/admittance/$0/template/createUser', // 模版创建人
    getProductVisitInfo: '/api/visit/products/$0/visitInfo', // 产品临床拜访详情
    admittanceTemplate: '/api/admittance/$0/template', // 模版列表
    admittanceType: '/api/admittance/promoteType', // 模版类型
    templateChunk: '/api/admittance/template/$0/common/chunk/$1/$2', // 获取初始
    templateFiled: '/api/admittance/template/$0/common/field', // 常用字段
    addAdmittance: '/api/admittance/template', // 添加模版
    admittanceDetail: '/api/admittance/template/$0', // 模版详情
    admittanceExist: '/api/admittance/template/code/exist', // 查看模版编码
    admittanceCopy: '/api/admittance/template/copy', // 模版复制
    admittanceNumber: '/api/admittance/$0/register/promoteType', // 登记数量统计
    admittanceRegister: '/api/admittance/$0/register', // 登记列表
    admittanceCreateUser: '/api/admittance/$0/register/createUser', // 登记人列表
    admittanceDraft: '/api/admittance/$0/register/draft', // 登记草稿
    admittanceAllTemplate: '/api/admittance/$0/template/$1', // 查看所有模版
    admittanceEditing: '/api/admittance/$0/register/$1/editing', // 查询是否有退出的登记
    getCollectFlow: '/api/info/collect/register/company/$0', // 流向数据查询
    createRegister: '/api/admittance/register', // 生成登记
    getPharmacyProductVisitInfo: '/api/visit/pharmacy/products/$0/visitInfo', // 产品药店拜访详情
    registerCheck: '/api/admittance/register/$0', // 查看登记
    updateField: '/api/admittance/register/$0/field', // 上传字段
    collectFlowGroup: '/api/info/collect/register/$0/flow/group', // 分组
    addFlowGroup: '/api/admittance/$0/info/collect/register/$1/flow/group', // 添加分组
    flowExport: '/api/admittance/$0/info/collect/register/flow/excel', // 流向数据Excel导入
    checkCollectFlowData: '/api/admittance/$0/info/collect/register/$1', // 查看已经引用流向数据
    checkVisitData: '/api/admittance/$0/visit', // 查看已经引用临床拜访数据
    addInfoRegister: '/api/admittance/$0/info/collect/register', // 添加终端调研数据
    addAdmittanceReg: '/api/admittance/register/$0/submit', // 生成登记
    pharmacyVisitCompany: '/api/pharmacy/visit/company/$0', // 药店拜访
    admittancePharmacy: '/api/admittance/$0/pharmacy/visit', // 已生成药店信息
    admittancePDF: '/api/admittance/register/$0/pdf', // 生成PDF
    admittanceDraftSave: '/api/admittance/register/$0/draft', // 保存草稿箱
    admittanceFlowInfo: '/api/admittance/$0/info/collect/register/flow', // 查看流向分组详情
    attachmentsTask: '/api/task/item/$0/attachments', // 推广任务准入分页
    admittanceCompany: '/api/admittance/register/company/$0', // 线上添加分页
    attachmentImport: '/api/task/item/$0/attachment/import', // 线上添加
    visitPharmacyUsers: '/api/pharmacy/visit/company/$0/createUsers', // 药店创建人列表
    registerCompanyCreate: '/api/info/collect/register/company/$0/createUser', // 创建人列表
    admittanceSummaryDown: '/api/task/download/$0/admittance/summary', // 汇总表下载
    pharmacyExtra: '/api/admittance/$0/pharmacy/extra/districtIds', // 查询药店地区范围符合
    unSyncCustomersCount: '/api/$0/customers/unSync/count', // 待同步临床客户个数
    admittancecreateUser: '/api/admittance/register/company/$0/createUser', // 待同步临床客户个数
    getRegisterUpdateTime: '/api/admittance/register/$0/updateTime', // 查询登记更新时间

    questionTask: '/api/task/$0/question', // 问卷总结
    setTaskFieldConfig: '/api/promote/task/list/field/config', // GET  获取推广任务列表字段设置  PUT  更新推广任务列表字段设置
    setFieldConfig: '/api/$0/$1/field/config', // GET  展示列表字段设置  PUT  更新展示列表字段设置

    checkAllToggle: '/api/abnormal/$0/check/all/toggle/1', // 检查一键检索是否需要提示
    checkAll: '/api/abnormal/$0/check/all/1', // 一键检索
    criterionSyncPlan: '/api/company/$0/$1/criterion/sync', // 任务查看是否开启同步
    criterionCheckPlan: '/api/company/$0/$1/criterion/sync', // 开关
    accountReset: '/api/try/account/$0/reset', // 开关
    infoFeedbackSetting: '/api/info/feedback/check/$0/condition', // 竞品条件设置

    // 按营销计划统计
    marketingPlanRegions: '/api/marketing/plan/$0/region', // 获取计划区域
    marketingPlanStatsOptions: '/api/marketing/plan/$0/stats/options', // 获取筛选项
    marketingPlanTasks: '/api/marketing/plan/tasks', // 推广任务列表
    marketingPlanAmountStats: '/api/marketing/plan/amount/stats', // 结算预算统计
    marketingPlanSummaryStats: '/api/marketing/plan/summary/stats', // 汇总
    marketingPlanDetailStats: '/api/marketing/plan/detail/stats', // 明细
    marketingPlanBudgetStats: '/api/marketing/plan/region/budget/stats', // 区域预算金额占比统计
    marketingPlanSettlementStats: '/api/marketing/plan/region/settlement/stats', // 区域结算金额占比统计

    protocolPermission: '/api/contact/$0/protocol/budget/button', // 协议相关按钮是否显示
    exportFieldConfig: '/api/promote/task/$0/list/export/field/config', // 获取导出筛选配置
    exportFieldExcel: '/api/promote/receiver/tasks/excel', // 导出筛选配置
    exportPlanFieldExcel: '/api/promote/receiver/plan/excel', // 导出筛选配置

    // 操作日志
    operationLog: '/api/company_user_logs', // 公司用户操作日志列表（分页）
    operationLogDetail: '/api/company_user_log/$0', // 公司用户操作日志详情

    downLoadTemplate: 'api/$0/productinfo/costing/template', // 批量编辑考核价

    // 组织架构
    getOrganization: '/api/$0/organization', // 获取整个组织架构的数据
    createDivision: '/api/$0/division', // 创建部门
    getDivisionDetail: '/api/$0/division/$1', // 获取部门详情
    getDivisionClerk: '/api/$0/division/$1/clerk', // 部门中的职员
    getAllUsers: '/api/$0/division/$1/users', // 部门查询公司用户列表
    changeChief: '/api/$0/division/$1/clerk/$2/chief', // 设置为主管
    deleteClerk: '/api/$0/division/$1/clerk/$2', // 删除员工
    changeUserDivision: '/api/$0/division/user/$1', // 用户批量调整部门

    // 更多附件
    attachmentItem: '/api/promote/task/$0/item/attachment', // 更多附件列表
    terminalItem: '/api/promote/task/item/attachment/$0/terminal?orderByServiceItemTerminal=true', // 终端列表
    attachmentUrls: '/api/promote/task/item/$0/attachment/url?orderByServiceItemTerminal=true', // 查询所有的附件
    attachmentFiles: '/api/promote/task/item/attachment/$0/file', // 保存附件
    terminalPic: '/api/promote/task/item/$0/attachment/url?orderByServiceItemTerminal=true',
    terminalIndex:
        '/api/promote/task/item/$0/attachment/file/order?serviceItemTerminalIdForFileOrder=$1&orderByServiceItemTerminal=true',
    terminalMap: '/api/promote/task/item/attachment/$0/terminal/startDate/map',
    // ?serviceItemTerminalIdForFileOrder=$1',

    queryEmployeeRule: '/api/company/employee/number/rule', // 查询公司员工工号生成规则
    doenloadUSers: '/api/download/users', // 下载用户列表
    batchChangeUsers: '/api/$0/users', // Excel批量修改工号，备注名，职称
    // 自定义规则
    supplementCodeRule: '/api/company/supplement/code/rule', // 查询/修改自定义编号生成规则
    getSupplementCode: '/api/$0/supplement/code', // 获取自定义编号

    // 流向
    queryFlowIsOpen: '/api/company/$0/flow', // 是否已经启用流向
    getFlowUsers: '/api/$0/flow/users', // 用户授权列表
    getCandidateUSer: '/api/$0/flow/user/candidate', // 查询候选人
    addFlowUSers: 'api/$0/flow/user', // 批量用户授权
    resetFlowPass: '/api/$0/flow/user/$1/password', // 重置密码
    deleteUSerFlow: '/api/$0/flow/user/$1', // 取消授权
    changeUSerFlow: '/api/$0/flow/user/$1/$2', // 修改权限
    getFlowTemplate: '/api/company/$0/flow/template', // 查看模版
    getFlowStatistic: '/api/$0/sales/statistic/month', // 按月查询统计
    getProductStatistic: '/api/$0/product/statistic', // 查询公司统计产品数据
    exchangeProductInfo: '/api/$0/product/statistic/selectable', // 换取产品信息
    flowStatisticStatus: '/api/$0/product/statistic/status', // 查询公司统计产品信息-数量，是否全部考核价
    flowProductMode: '/api/$0/product/statistic/mode', // 获取当前产品选择是什么模式 1-全产品 2-产品线 3 -自定义

    // 信息收集-模版管理
    setTemplateIsDisable: '/api/info/collect/$0/template/$1/isDisable', // 设置模版是否禁用
    deleteTemplate: '/api/info/collect/$0/template/$1', // 删除模版和删除模版引用
    // 准入模版
    setAdmitIsDisabled: '/api/admittance/$0/template/$1/isDisable', // 设置模版是否禁用
    deleteAdmitTemplate: '/api/admittance/$0/template/$1', // 删除模版

    // 子预算
    budgetItemButton: '/api/contact/$0/protocol/$1/budget/item/button', // 协议子预算按钮是否显示(是否开启规则或设置过)
    getBudgetItem: '/api/contact/protocol/$0/budget/item/page', // 查询协议子预算分页
    addBudgetItem: '/api/contact/protocol/$1/budget/item', // 协议预算中添加协议子预算
    addBudgetItemCheck: '/api/contact/protocol/$1/budget/item/validation', // 协议预算中添加协议子预算金额校验
    queryItemBudget: '/api/contact/protocol/$0/budget/item/money', // 查询协议子预算金额详情
    getBudgetItemLog: '/api/contact/protocol/budget/item/$0/log/page', // 查询协议子预算日志
    getBudgetItemTask: '/api/contact/protocol/budget/item/$0/task/page', // 查询协议子预算关联的推广任务
    changeBudgetItem: '/api/contact/protocol/budget/item/$0', // 协议预算中更新协议子预算
    changeBudgetItemCheck: '/api/contact/protocol/budget/item/$0/validation', // 协议预算中更新协议子预算金额校验
    batchupdateTemplate: '/api/contact/$0/protocol/budget/item/$1/batchupdate/template', // 下载上游用户批量分配协议子预算清单模版
    batchupdateUploadExcel: '/api/contact/$0/protocol/budget/item/$1/batchupdate', // 上游用户返回上传的Excel下载链接 批量分配协议子预算
    batchupdateUploadProgress: '/api/contact/protocol/budget/item/batchupdate/progress/$0', // 上游用户获取批量分配协议子预算的进度状态

    // 推广任务回滚
    taskRollback: '/api/promote/task/rollback/record', // 分页查询推广任务回滚日志
    taskRollbackDetail: '/api/promote/task/rollback/record/$0/detail', // 分页查询推广任务回滚日志
    rollbackCandidate: '/api/$0/task/rollback/candidate', // 选择回滚任务列表
    candidateDetail: '/api/$0/task/rollback/candidate/detail', // 选择回滚任务列表-通过id查看任务详情
    taskRollbackSend: '/api/task/rollback', // 审批通过的任务状态回滚

    // 结算
    settlementStats: '/api/$0/settlement/stats', // 结算统计分页
    deleteSettlementStats: '/api/settlement/stats/$0', // 删除结算统计
    settlementCertLog: '/api/$0/settlement/stats/cert/logs', // 结算凭证日志
    settlementStatsUsers: '/api/$0/settlement/stats/users', // 登记人列表
    settlementStatsProtocols: '/api/settlement/stats/$0/protocols', // 引用协议列表
    settlementStatsTasks: '/api/settlement/stats/$0/tasks', // 引用任务列表
    sendSettlementEmail: '/api/settlement/stats/$0/mail', // 发送邮件
    getSendEmailProgress: '/api/settlement/stats/mail/$0', // 获取发送邮箱的进度
    setPaymentTemplate: '/api/task/payment/info/statistical/template', // 设置批量付款登记
    paymentTemplate: '/api/task/payment/info/statistical/template', // 获取批量付款登记设置
    settlementCertRule: '/api/$0/settlement/stats/cert/rule', // 获取结算凭证编号规则
    settlementSetCertRule: '/api/settlement/stats/cert/rule', // 修改结算凭证编号规则
    statisticalDown: 'api/task/payment/info/statistical/template/async', // 下载模版
    statisticalImport: '/api/task/payment/info/statistical/import/$0', // 上传
    settlementCretCodes: '/api/$0/settlement/stats/cert/codes', // 获取凭证编号
    settlementStatsSave: '/api/settlement/stats/1', // 编号生成
    settlementSendResultInfo: 'api/settlement/stats/up', // 效果评估表
    getSettlementSultInfoIds: '/api/settlement/apply/effect/assess/$0/list', // 获取效果评估表的所有id
    getSettlementSultInfoIds2: '/api/settlement/apply/effect/assess/$0/partyB',
    getSettlementLastEmail: '/api/$0/settlement/stats/last/mail', // 获取邮箱地址
    getSettlementStatisticalCount: '/api/settle/task/statistical/count', // 获取结算列表的总金额
    settlementWordComposingSetting: '/api/$0/settlement/stats/composing/setting/$templateType', // 获取结算凭证导出word 排版设置
    settlementWordContentSetting: '/api/$0/settlement/stats/content/setting', // 获取结算凭证导出word 内容设置
    settlementWordContentSaveSetting: '/api/$0/settlement/stats/content/setting/$templateType', // 获取结算凭证导出word 内容设置保存
    settlementZipExport: '/api/settlement/stats/$0/export', // 结算Zip导出
    settlementExcelExport: 'api/settlement/stats/$0/export/excel/async', // 结算excel导出
    newZipExport: 'api/settlement/stats/$0/export/V2', // 刘玉兴新的文件导出接口，好像是把已经生成的各类文件，在调用接口时合并成zip导出
    settlementStatsExportSetting: '/api/settlement/stats/export/excel/setting/$0', // 查询导出excel的服务区间
    updateSettlementStatsExportSetting: '/api/settlement/stats/export/excel/setting', // 更新导出excel的服务区间
    agreementSettlementStats: '/api/settlement/stats/$0/agreement', // 审核通过结算凭证
    rejectionSettlementStats: '/api/settlement/stats/$0/rejection', // 审核驳回结算凭证
    settlementStatsAuditors: '/api/settlement/stats/$0/auditors', // 结算凭证待审核人
    // 获取结算凭证导出模版设置
    settlementTemplateExportSetting: 'api/settlement/template/setting',
    settlementTemplateSettingKind: 'api/settlement/template/setting/$templateType',

    // 补充协议
    getOperatorId: '/api/protocol/supplement/protocolIds', // 保存协议id集合并获取操作id
    getAllprotocol: '/api/protocol/supplement/operateId/$0', // 根据操作id获取协议id合集
    initserviceItemSet: '/api/protocol/supplement/init/serviceItem/toggle/setting', // 初始化结算标准开关设置(服务项目资质)
    supplementStand: '/api/protocol/supplement/settlement/standard', // 查询补充协议模版结算标准
    getOriginProtocols: '/api/contact/company/$0/protocols', // 公司协议列表
    SetSupplementList: '/api/protocol/supplement/setting/info/list', // 根据操作id和设置类型获取补充协议的设置信息列表
    setSupplementCount: '/api/protocol/supplement/setting/count', // 获取各项设置影响的协议数量
    saveSupplement: '/api/protocol/supplement/setting', // 保存补充协议设置
    submitSupplement: '/api/protocol/supplement/generate', // 生成补充协议
    supplementReson: '/api/protocol/supplement/$0/generate/info', // 生成补充协议的生成情况
    submitCheckSupplement: '/api/protocol/supplement/check', // 校验协议与公司已有协议的重复性
    submitCheckSupplementResult: '/api/protocol/supplement/check/$0', // 校验协议与公司已有协议的重复性
    getSupplementModification: '/api/protocol/supplement/$0/modification', // 查看修改位置
    suppleSignedversion: '/api/contact/protocol/$0/allSignedVersion', // 单个协议所有已签约的版本
    checkSuppleService: '/api/protocol/supplement/check/serviceItemToggle', // 服务项目资质能否选择维持生效协议

    // 审批管理
    examineManage: '/api/$0/approval/line', // 公司查询审批管理列表（废弃）
    examineManageList: '/api/audit/flow', // 公司查询审批管理列表
    deleteApprovalLine: '/api/$0/approval/line/$1', // 删除审批线
    addAuditoeInit: '/api/$0/auditor/temp/init', // 获取初始化ID
    addAuditoeDefine: '/api/$0/auditor/temp/$1', // 添加人员
    changeAuditoeDefine: '/api/$0/auditor/temp/$1/$2', // 替换人员
    scenesAddable: '/api/$0/approval/line/$1/scenes/addable', // 审批线能否添加场景值
    // 自定义审批流详情
    approvalAuditors: '/api/$0/approval/line/$1/auditors', // 获取所有审批线所有数据
    approvalLineDetail: '/api/$0/approval/line/$1', // 获取审批线基本情况
    addAuditorLine: '/api/$0/auditor/line/$1/auditor', // 添加人员
    changeAuditorLine: '/api/$0/auditor/line/auditor/$1', // 替换人员
    // 服务商审批权限设置
    getAuditSupports: '/api/company/$0/audit/supports', // 查询公司审批权限列表支持内容
    getContactAuditList: '/api/company/contact/$0/$1/audit', // 查询公司审计模式
    getAuditOther: '/api/company/$0/$1/audit/other', // 其他审批流列表
    changeAuditWay: '/api/company/contact/$0/$1/$2', // 切换公司任务审计模式
    getContactSupplier: '/api/company/$0/contact/$1/$2/supplier', // 查询应用服务商
    postContactSupplier: '/api/company/contact/$0/$1/normal/repetition', // 将当前服务商推广任务审批的简易审批模式设置应用到其他服务商 并开启其他服务商推广任务审批的简易审批模式
    postCascadeRepetition: '/api/company/contact/$0/$1/cascade/repetition', // 批量更新审计模式
    getContactAuditor: '/api/company/contact/$0/$1/auditor', // 查询审计人员
    deleteContactAuditor: '/api/company/contact/$0/auditor/$1', // 删除审计人员
    getContactAuditorList: '/api/company/contact/$0/$1/auditor/distinct', // 查询审计人员 - 去重
    productionLineDetail: '/api/company/$0/production/line/auditor', //   全部产品线
    approvalLineDetailDefine: '/api/company/$0/approval/line/$1/auditor', // 自定义 审批线

    // 医药代表
    mdecineCategory: '/api/medicine/category', // 药品类别
    mdecineCategoryScope: '/api/medicine/category/$0/scope', // 治疗领域
    collectTask: '/api/sales/record/collect/tasks', // 任务列表
    collectTaskCreators: '/api/sales/record/collect/task/creators', // 任务列表-发布人
    publishCollectTask: 'api/sales/record/collect/task', // 发布任务
    collectTaskDetail: '/api/sales/record/collect/task/$0', // 获取任务详情
    collectUser: '/api/sales/record/collect/task/$0/users', // 查询收集任务的人员列表,
    collectUserAddable: '/api/sales/record/collect/task/$0/users/addable', // 查询成员列表
    changeCollectTask: '/api/sales/record/collect/task/$0', // 更新任务
    deleteCollectUser: '/api/sales/record/collect/task/user/$0', // 删除责任人-收集任务
    registerList: '/api/sales/record/register/$0/collect/detail', // 责任人采集明细列表
    registerListUsers: '/api/sales/record/register/$0/collect/detail/users', // 责任人采集明细列表 -- 责任人
    changeRegisterStatus: '/api/sales/record/register/collect/status', // 批量修改状态
    exportExcelRegister: '/api/sales/record/register/$1/download/$0', // 下载
    recordRegisterDetail: '/api/sales/record/register/$0', // 获取登记详情
    registerLogs: '/api/sales/record/register/$0/logs', // 审批日志列表
    changeRegisterRemark: '/api/sales/record/register/$0/remark', // 修改服务商备注信息
    majorCategories: '/api/college/$0/major/categories', // 查询门类
    majorSubcategories: '/api/college/major/category/$0/subcategories', // 查询专业类
    majorSubcategoryName: '/api/college/major/category/subcategory/$0/majors', // 查询专业名称
    searchColleges: '/api/colleges', // 大学查询
    mdecineCategoryChange: '/api/sales/record/collect/task/$0/medicine/category', // 药品类别
    mdecineCategoryScopeChange: 'api/sales/record/collect/task/$0/medicine/category/$1/scope', // 治疗领域
    medicalRegisterUnique: '/api/$0/sales/record/register/unique', // 查询医药代表列表
    medicalRegisterProviders: '/api/$0/sales/record/providers', // 企业视图列表
    unBelongedRepresentative: '/api/$0/sales/record/register/unique/unbelonged', // 未归属的医药代表数量
    medicalRegisterSalesRecord: '/api/$0/sales/record/provider/$1/unique/list', // 服务商查看可以添加的人
    medicalRegisterAddedRecord: '/api/sales/record/provider/$0/unique', // 服务商的医药代表列表
    deleteMedicalRegisterAddedRecord: '/api/sales/record/provider/$0/unique/$1', // 移除关联的医药代表
    updateRecordRegisterUnique: '/api/sales/record/register/unique/$0', // 更新医药代表备案号
    registerSameNamesRecord: '/api/sales/record/register/unique/$0/registers', // 查看相同姓名的人
    toggleRegisterSameNamesRecord: '/api/sales/record/register/unique/$0/register/$1', // 切换相同姓名的人的信息
    downloadSalesRecordRegisterTemplate: '/api/$0/sales/record/register/unique/excel', // 批量更新备案号excel数据模版下载
    medicalRegisterSalesUsers: '/api/sales/record/provider/$0/unique/users', // 服务商查看可以添加的人
    medicalImportCollectTask: '/api/sales/record/collect/task/import', // 本地导入预登记管理任务
    medicalBatchImportPhoto: '/api/sales/record/collect/task/import/photo', // 医药代表备案批量导入采集人图片
    medicalRegisterPhoto: '/api/sales/record/register/collect/photo', // 修改登记信息图片
    batchDeleteRegister: '/api/sales/record/register/batch', // 批量删除登记

    // 备案信息监测
    medicalAbnormalList: '/api/$0/sales/register/detection/condition', // 备案信息检测列表
    medicalAbnormalToggle: '/api/sales/register/detection/condition/$0/toggle', // 修改备案信息检测开关
    registerDetectionToggle: '/api/$0/task/$1/sales/register/detection/toggle', // 判断是否开启备案监测
    registerDetectionStatus: '/api/$0/task/$1/sales/register/detection/task', // 查询备案监测检索状态
    registerDetectionWays: '/api/$0/sales/register/detection/ways', // 查询备案监测方式
    updateRegisterDetectionWays: '/api/$0/sales/register/detection/ways/$1', // 编辑备案监测方式
    registerDetectionResult: '/api/$0/task/$1/sales/register/detection', // 查询监测结果
    registerDetectionResultPdf: '/api/$0/task/$1/sales/register/detection/pdf', // 查询监测结果

    // 试用版
    trailCode: '/api/company/trail/code', // 判断试用码是否正确
    openTrail: '/api/company/$0/trail', // 开通试用
    flowRedirectToken: '/api/$0/flow/redirect/token', // 获取云商临时token

    // 终端维护
    terminalDetail: '/api/info/collect/maintenance/register/$0', // 获取《编辑终端维详情》内容
    taskTerminalList: '/api/promote/task/$0/terminal/maintenance', // 已添加的终端维护列表
    batchUpdateTaskTerminalPrice: '/api/promote/task/$0/terminal/maintenance/batch', // 批量修改终端维护金额
    updateTaskTerminal: '/api/promote/task/terminal/maintenance/$0', // 修改终端维护金额
    batchDeleteTaskTerminal: '/api/promote/task/terminal/maintenance', // 从任务中移除终端维护
    selectTaskMaintenanceList: '/api/promote/task/$0/terminal/maintenance/select', // 终端维护选择列表
    terminalMaintenanceSummary: '/api/task/download/$0/terminal/maintenance/summary', // 终端维护汇总表下载
    settlementType: '/api/task/item/$0/settlement/info',
    taskTerminalOffline: '/api/promote/task/terminal/maintenance/offline/$0', // 获取线下终端维护文件详情

    // 任务指派
    checkLastTaskType: '/api/task/assign/version/type', // 获取某成员上次选择的类型
    getAssociateTeamTask: '/api/task/assign/team/associated/$versionId', // 查询自营团队导入方式的指派的任务
    teamTaskRangeModify: '/api/task/assign/team', // (查询，保存，删除)按自营团队导入方式的指派的任务范围
    checkTeamTaskModify: '/api/task/assign/team/$versionId', // 下一步、编辑
    isWithinArea: '/api/contact/protocol/$0/isWithinArea', // 判断是否超过地域
    isWithinAreaTask: '/api/promote/task/subcontract/$0/isWithinArea', // 分包查看区域范围
    getTeamTaskBasic: '/api/promote/task/$teamTaskId/basic', // 获取关联任务的基础信息
    getSingleVerTaskDetail: '/api/task/assign/version/$versionId', // 询单个任务指派版本详情
    getTeamTaskPromoteItems: '/api/task/$teamTaskId/item/accessible', // 获取关联任务的推广项目
    checkTeamFinishTaskModify: '/api/task/assign/team/finish/$versionId', // 完成指派
    getBudgetTaskItemDetails: '/api/task/assign/group/task/$taskId', // 按任务查询本任务的预算项目组详情
    selectSelfTaskLists: '/api/promote/self/$partyAId/tasks', // 选择自营团队任务
    checkSelfTeamAbled: '/api/self/support/bind', // 是否启用自营团队
    addTaskAssignVersion: '/api/task/assign/version', // 新增修改任务指派版本
    getTaskAssignProduct: '/api/task/assign/product/$0', // 查询当前版本已选中的产品
    clearTaskAssignProduct: '/api/task/assign/product/$0', // 清空当前版本已选中的产品
    updateTaskAssignProduct: '/api/task/assign/product', // 保存当前版本已选中的产品
    deleteTaskAssignProduct: '/api/task/assign/product/$0/$1', // 删除当前版本已选中的产品
    updateTaskAssignArea: '/api/task/assign/area', // 修改任务区域
    updateTaskAssignHospitalArea: '/api/task/assign/area/hospital', // 修改任务医院区域
    deleteTaskAssignArea: '/api/task/assign/area/$0/$1', // 单个删除当前版本已选中的区域
    getTaskAssignAreas: '/api/task/assign/area/$0', // 查询当前版本已选中的区域
    taskAssignVersion: '/api/task/assign/version/page', // 查询任务指派版本页面
    taskAssignVersionUsers: '/api/task/assign/version/user/$0', // 查询任务指派有版本的操作人
    taskAssignVersionDetail: '/api/task/assign/version/$0', // 删除任务指派版本
    taskAssignSetting: '/api/task/assign/setting/$0', // 查询任务分配设置
    updateTaskAssignSetting: '/api/task/assign/setting', // 更新任务分配设置
    taskAssignSettingCheck: '/api/task/assign/version/check/$0', // 检查任务分配设置是否能切换
    taskAssignItemSetting: '/api/assign/item/setting/$0', // 查询全部项目明细分配方案
    addTaskAssignItemSetting: '/api/assign/item/setting', // 添加项目明细分配方案
    getTaskAssignItemSetting: '/api/assign/item/setting/detail/$0', // 项目明细分配方案详情
    intelligentAssignItemAsync: '/api/intelligent/assign/item/budget/async', // 智能分配项目预算
    intelligentAssignItemResult: '/api/intelligent/assign/async/$0', // 任务指派获取异步生成结果
    taskAssignItemBudget: '/api/task/assign/$0/item/budget', // 获取项目预算
    updateTaskAssignItemBudget: '/api/task/assign/item/budget', // 更新项目预算
    getTaskAssignItemBudgetPrice: '/api/task/assign/item/$0/budget', // 获取项目单价
    getTaskAssignRule: '/api/task/assign/name/rule/$0', // 查询当前版本的任务名称规则
    getTaskAssignRuleExample: '/api/task/assign/name/rule/$0/example', // 查询当前版本的任务名称规则示例
    updateTaskAssignRule: '/api/task/assign/name/rule', // 保存当前版本的任务名称规则
    taskAssignCompany: '/api/task/assign/company', // 保存/删除选择的对象
    getTaskAssignCompany: '/api/task/assign/company/$0', // 查询当前版本已选中的对象
    taskAssignPartyB: '/api/task/assign/company/partyB/$0', // 查询可选择的对象
    getTaskAssignDraft: '/api/task/assign/draft', // 查询生成的任务
    getTaskAssignDraftCount: '/api/task/assign/draft/count/$0', // 查询生成的任务的数量
    deleteTaskAssignDraft: '/api/task/assign/draft/$0', // 删除生成的任务
    asyncUpdateTaskAssign: '/api/task/assign/draft/async', // 异步生成任务指派结果
    getTaskAssignResult: '/api/task/assign/draft/async/$0', // 任务指派获取异步生成结果
    getTaskAssignFailCount: '/api/task/assign/company/count/fail/$0', // 查询选择的对象的失败的个数
    clearTaskAssignTask: '/api/task/assign/draft/clean/$0', // 清空版本任务
    updateTaskAssignDraftArea: '/api/task/assign/draft/areas', // 修改任务区域
    taskAssignSettingUnused: '/api/task/assign/setting/$0/false', // 查询任务分配设置
    getTaskAssignQuick: '/api/task/assign/draft/quick', // 快速查找未填写项
    getTaskCompleteSetting: '/api/task/assign/draft/version/setting/$0', // 查询当前任务分配方式
    createTaskAssignAsyncResult: '/api/task/assign/async', // 异步任务指派结果
    getTaskAssignAsyncResult: '/api/task/assign/async/$0', // 任务指派获取异步生成结果
    getTaskTypeSetting: '/api/task/assign/version/setting/$0', // 获取任务指派的分配设置
    changeStrictCase: '/api/task/assign/draft/version/$0/setting/$1', // 计划指派-第二步设置任务预算-智能分配明细-设置严格分配
    taskAssignBudgetExcel: '/api/task/assign/$0/budget/excel', // 任务指派配置任务预算excel表格
    batchUpdateTaskAssignBudget: '/api/task/assign/$0/async/budget/batchUpdate', // 上传的Excel下载链接批量完善协议预算清单
    batchUpdateBudgetProgress: '/api/task/assign/budget/batchupdate/progress/$0', // 获取批量完善协议预算清单的进度状态
    calcExceptBudget: '/api/task/item/$0/calc/budget', // 计算预计预算金额
    promoteAudit: '/api/promote/plan/$0/audit', // 检验上游是否开启计划审批
    taskAssignDraftTask: '/api/task/assign/draft/all', // 按版本查询所有的任务，包括已删除的
    getTerMaintain: '/api/$0/terminal/maintenance/criterion/$1', // 终端维护设置

    startCheckTer: '/api/$0/task/$1/terminal/maintenance/check/task', // 开始检索终端维护异常项
    getTerTabs: '/api/$0/task/$1/terminal/maintenance/$2/check/result', // 详情
    getTerDetail: '/api/1/task/$0/terminal/maintenance/month/$1/items', // 详情
    getPersonalInfo: '/api/contact/$0/info', // 获取甲乙双方基本信息
    getProtocolBothMemberInfo: '/api/contact/$0/info2', // 获取甲乙双方基本信息
    getProjectGroupSetting: '/api/task/assign/group/$0/$1', // 查询项目组设置
    imputProjectGroupSetting: '/api/task/assign/group/import/$0/$1', // 导入项目组设置
    saveProjectGroupSetting: '/api/task/assign/group/$0', // 保存项目组设置
    getCanSelectAccessibleItem: '/api/task/assign/group/protocol/$0', // 获取该版本可以选择的服务项目
    getCanSelectAccessibleItemV2: '/api/task/assign/group/protocol/$0/v2', // 获取该版本可以选择的服务项目v2
    getProjectGroupSettingForTaskID: '/api/task/assign/group/task/$0/$1', // 按任务查询项目组设置
    getTaskBudgetProjectGroupInfo: '/api/task/assign/group/task/$0', // 按任务查询本任务的预算项目组详情
    getProjectGroupUpdateTime: '/api/task/assign/group/updateTime/$0/$1', // 获取项目组更新时间
    getServiceItems: '/api/task/assign/group/serviceItem/$0', // 计划指派-按推广项目明细分配-项目明细分配方案-编辑/创建-获取推广服务项目
    saveTaskAssignTotalSettlement: '/api/task/assign/setting/total', // 计划指派-按项目明细指派-保存任务总结算金额

    /* 风险监控 */
    riskServeState: '/api/apply/monitor/$0/state',
    applyForRisk: '/api/apply/monitor/$0', // 申请开通风险监控
    getDynamicList: '/api/monitor/info', // 获取动态列表
    saveCondition: '/api/monitor/$0/dimensionality', // 推送维度
    getCondition: '/api/monitor/$0/dimensionality/$1', // 获取条件
    controlComNum: '/api/monitor/info/$0/count', // 接送企业数量
    controlComUpNum: '/api/monitor/info/$0/limitation', // 接送企业上限

    getPushper: '/api/push/monitor/$0/user', // 获取推送人员 /添加推送人员
    addUserLisrt: '/api/monitor/users', // 推送成员
    changePushState: '/api/push/monitor/user', // 修改推送状态
    riskComList: '/api/monitor/$0', // 监控企业列表
    riskComMsg: '/api/monitor/$0/company/info', // 公司信息
    addRiskComList: '/api/monitor/contact/partya/$0', // 添加公司列表

    changeRiskComState: '/api/monitor/$0/state/$1', // 修改监控企业状态
    eiskScan: '/api/risk/scan/$0', // 企查查风险扫描 获取数据
    eiskScanStatistics: '/api/risk/scan/$0/statistics', // 企查查风险扫描 获取统计数据
    eiskScanDetail: '/api/risk/scan/$0/info', // 企查查风险扫描Info 获取数据
    // 批量删除
    batchDeleteMoney: '/api/task/payment/info/async', // 批量删除结算信息

    // 推广总结报告
    summaryReportItem: '/api/summary/report/detail', // 获取事项对应的数据来源 GET
    summaryReport: '/api/summary/report/up/{companyId}', // 获取所有事项 GET
    saveReport: '/api/summary/report/up', // 上游公司修改推广总结报告模版 PUT
    batchDeleteRes: '/api/task/payment/info/async', // 获取批量删除结果
    attachmentSelect: '/api/promote/task/$0/item/attachment/select', // 获取所有项目

    // 实名认证
    idCardOCR: '/api/person/id/crad/ocr', // 身份证ocr识别接口
    personVerifyCode: '/api/fadada/person/verify/code', // 获取个人实名认证验证码接口
    personVerify: '/api/fadada/person/verify', // 校验个人实名认证手机号(三要素)认证接口
    payForVerify: '/api/fadada/person/verify/order/wx', // 微信购买个人实名认证次数
    payResult: '/api/fadada/signature/order/$0/status', // 查询订单状态
    personVerifyInfo: '/api/fadada/person/threeEle', // 查询个人实名认证三要素

    // 协议签章
    signerTreeStaff: '/api/company/contact/$0/auditor/$1/sign', // 按服务商审批 设为、取消签名人
    signerTreeStaffDefined: '/api/$0/auditor/line/auditor/$1/sign', // 自定义审批设为、取消签名人
    checkSignerRule: '/api/approval/rule/config/$0/signer', // 校验当前审批流是否设置了签名人

    // 任务签章
    fadadaTaskSignUrl: '/api/promote/task/$0/sign/url', // 获取推广任务法大大签署链接
    fadadaSignresult: '/api/fadada/extsign/notify/$0/result', // 查询签署结果异步通知回调结果

    // 任务列表合计金额
    businessDataMount: '/api/promote/receiver/tasks/statistics/settlement',
    // 添加结算凭证更多报告
    addSetItemMoreReport: '/api/settlement/stats/more/report/$0',
    settlementStatsMoreUsers: '/api/$0/settlement/stats/users/more',

    // 更多报告导出
    moreExport: '/api/settlement/stats/$0/export',

    // 获取公司修改信息
    comPanyIsChange: '/api/company/change/info/$0',

    // 添加协议获取结算标准
    // 6091
    getActivity: '/api/compliance/item/activity', // 获取所有活动类型
    getAccessoryList: '/api/compliance/draft/$0/$1',
    accessoryDetail: '/api/compliance/item/$0/condition/$1', // 设置详情
    accessoryUpDateInfo: '/api/compliance/draft/$0', // 切换更新
    addConditonList: '/api/activity/registers/subkey', // 添加条件项
    addAccessoryList: '/api/compliance/item/$0', // 添加合规标准
    delAccessory: '/api/compliance/item/$0', // 删除一个合规标准
    updateAccessory: '/api/compliance/item', // 更新一个合规标准
    upPreView: '/api/compliance/info/preview/$0', // 预览准则
    issueAccessory: '/api/compliance/publish/$0', // 发布准则
    // 推广服务项目-输出设置-模版管理
    getOutputSettingInfos: '/api/company/$companyId/service/item/config', // 公司服务项目获取GET
    putOutputSettingInfos: '/api/company/$companyId/service/item/config', // 公司服务项目设置PUT

    // 添加协议获取结算标准

    // 实名认证fadada
    getFadadaUrl: '/api/fadada/person/verify/url', // 获取法大大实名认证地址

    promoteTypeList: '/api/activity/promote/type', // 推广项目种类列表
    // 添加协议获取结算标准
    protocolTemplateStandard: '/api/$0/protocolTemplate/standard/settlements',
    updateStandardSettlementDefaultParam: '/api/$0/standard/settlement/default/params', // 修改本地任务人数限制
    updatePeopleLinitBySettlementStandardId: '/api/standard/settlement/$0/default/params', // 修改本地任务人数限制新接口
    // 终端维护新增照片重复性异常详情
    terPhotoRepeatDetail: '/api/$0/task/$1/terminal/maintenance/image/repeat/result',
    // 学术活动举办重复性列表
    academicActivityRepeat: 'api/$0/task/$1/activity/check/repeat',
    verifyVersionChange: '/api/task/assign/$0/check/protocol', // 校验协议版本变更
    verifyTaskVersionChange: '/api/task/assign/draft/$0/check/protocol', // 校验协议版本变更
    academicActivityRepeatDetail: 'api/$0/task/activity/check/repeat/detail/$1',
    // 6823统计自定义导出内容
    agreementList: '/api/$0/derived/setting', // 协议自定义导出
    allAgreementList: '/api/derived/setting/all',
    staticTaskList: '/api/task/download/$0/derived/setting', // 任务自定义导出
    allStaticTaskList: '/api/task/download/derived/setting/all',

    getModulePermits: '/api/module/permit', // 读取当前用户在当前公司的功能模块权限列表
    hideTaskAssign: '/api/task/assign/version/$0', // 删除（实为隐藏）任务指派
    projectResource: '/api/promote/task/$0/unlimited', // 项目来源
    serviceList: 'api/promote/task/$0/unlimited/serviceItem', // 推广项目下拉
    projectResouceDownload: '/api/promote/task/$0/unlimited/excel/async', // 项目来源下载
    fadadaCheckAll: '/api/fadada/check/infoAndAuth/protocol', // 电子签约批量校验
    TerMaintenancePersonnel: 'api/task/terminal/maintenance/check/$0/abnormal/option', // 终端维护异常项-维护人员列表
    getTerAbnormalDetailData: 'api/task/terminal/maintenance/check/abnormal/detail', // 查询终端维护异常数据结果
    delTerAbnormalDetailData: 'api/$0/task/$1/terminal/maintenance/$2/check/result', // 删除终端维护异常数据结果

    registerTask: '/api/register/task/$0/$1/$2/import' // 导入登记任务
};

module.exports = api;
